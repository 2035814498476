import React, { Component } from 'react'
import { generatePath, useNavigate } from "react-router";
import { Link } from 'react-router-dom';

import ApiBackend from '../api-backend/ApiBackend'
import { createFullPath } from '../../routing/AppRoutes';
import { withRouter } from '../../routing/withRouter';

class IngredientList extends Component {

  constructor(props) {
    super(props);
  
    this.state = {
      loaded : !!props.location.state?.ingredients,
      ingredients : this.props.location.state?.ingredients || {},
    };
  }

  componentDidMount() {
    if(!this.state.loaded) { this.update(); } // initial update
    this.interval = setInterval(() => this.update(), 100000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async update(ingredients) {
    if(ingredients == null) {
      ingredients = (await ApiBackend.Ingredients.listAll()).ingredients;
    } 

    this.setState({
      loaded : true,
      ingredients : ingredients,
    });
  }


  render() {
    return (
      <>
        <h1>Alle Zutaten</h1>
        <p><Link to={createFullPath("Ingredient", "Create")}>Neue Zutat erstellen</Link></p>
        
        { !this.state.loaded ? (<p><em>Lade vorhandene Zutaten...</em></p>) : (
          <div className="list-group">{this.state.ingredients.map( (ingredient) => (
            <LinkNavigate key={ingredient.id} 
              to={generatePath(createFullPath("Ingredient","Detail"), {id: ingredient.id})}
              className="list-group-item list-group-item-action"
            > 
              {ingredient.name}

              <div className="float-right">
                <Link to={generatePath(createFullPath("Ingredient", "Edit"), {id: ingredient.id}) }>Bearbeiten</Link>
              </div>
            </LinkNavigate>))}
          </div>
        )}
      </>)
  }
}

export default withRouter(IngredientList)


function LinkNavigate(props) {
  const style = !props.isVariant ? {cursor:"pointer"} : {paddingLeft: "2em", cursor:"pointer"};
  let navigate = useNavigate();

  function openDetails(eventArgs) {
    // avoid opening the details in case of clicking on links
    if (eventArgs.target.nodeName === "DIV") {
      // create the path and navigate foreward
      navigate(props.to);
    }
  }
  return (
    <div className={props.className} onClick={openDetails} style={style}>
      {props.children}
    </div>
  )
}
