/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    VariantServings,
    VariantServingsFromJSON,
    VariantServingsFromJSONTyped,
    VariantServingsToJSON,
} from './';

/**
 * 
 * @export
 * @interface AddMealInWeekplanRequest
 */
export interface AddMealInWeekplanRequest {
    /**
     * 
     * @type {number}
     * @memberof AddMealInWeekplanRequest
     */
    recipeId?: number;
    /**
     * 
     * @type {number}
     * @memberof AddMealInWeekplanRequest
     */
    servings?: number;
    /**
     * 
     * @type {Array<VariantServings>}
     * @memberof AddMealInWeekplanRequest
     */
    variants?: Array<VariantServings> | null;
}

export function AddMealInWeekplanRequestFromJSON(json: any): AddMealInWeekplanRequest {
    return AddMealInWeekplanRequestFromJSONTyped(json, false);
}

export function AddMealInWeekplanRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddMealInWeekplanRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipeId': !exists(json, 'recipeId') ? undefined : json['recipeId'],
        'servings': !exists(json, 'servings') ? undefined : json['servings'],
        'variants': !exists(json, 'variants') ? undefined : (json['variants'] === null ? null : (json['variants'] as Array<any>).map(VariantServingsFromJSON)),
    };
}

export function AddMealInWeekplanRequestToJSON(value?: AddMealInWeekplanRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipeId': value.recipeId,
        'servings': value.servings,
        'variants': value.variants === undefined ? undefined : (value.variants === null ? null : (value.variants as Array<any>).map(VariantServingsToJSON)),
    };
}


