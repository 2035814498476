/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeMeta,
    RecipeMetaFromJSON,
    RecipeMetaFromJSONTyped,
    RecipeMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface WeekplanMealVariantDetailed
 */
export interface WeekplanMealVariantDetailed {
    /**
     * 
     * @type {RecipeMeta}
     * @memberof WeekplanMealVariantDetailed
     */
    recipe?: RecipeMeta;
    /**
     * 
     * @type {number}
     * @memberof WeekplanMealVariantDetailed
     */
    servings?: number;
}

export function WeekplanMealVariantDetailedFromJSON(json: any): WeekplanMealVariantDetailed {
    return WeekplanMealVariantDetailedFromJSONTyped(json, false);
}

export function WeekplanMealVariantDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekplanMealVariantDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipe': !exists(json, 'recipe') ? undefined : RecipeMetaFromJSON(json['recipe']),
        'servings': !exists(json, 'servings') ? undefined : json['servings'],
    };
}

export function WeekplanMealVariantDetailedToJSON(value?: WeekplanMealVariantDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipe': RecipeMetaToJSON(value.recipe),
        'servings': value.servings,
    };
}


