/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IngredientDetailedNoId,
    IngredientDetailedNoIdFromJSON,
    IngredientDetailedNoIdFromJSONTyped,
    IngredientDetailedNoIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface UpdateIngredientRequest
 */
export interface UpdateIngredientRequest {
    /**
     * 
     * @type {IngredientDetailedNoId}
     * @memberof UpdateIngredientRequest
     */
    ingredient: IngredientDetailedNoId;
}

export function UpdateIngredientRequestFromJSON(json: any): UpdateIngredientRequest {
    return UpdateIngredientRequestFromJSONTyped(json, false);
}

export function UpdateIngredientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateIngredientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ingredient': IngredientDetailedNoIdFromJSON(json['ingredient']),
    };
}

export function UpdateIngredientRequestToJSON(value?: UpdateIngredientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ingredient': IngredientDetailedNoIdToJSON(value.ingredient),
    };
}


