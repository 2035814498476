/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SetStockAmountRequest
 */
export interface SetStockAmountRequest {
    /**
     * 
     * @type {boolean}
     * @memberof SetStockAmountRequest
     */
    setToValue?: boolean;
    /**
     * 
     * @type {number}
     * @memberof SetStockAmountRequest
     */
    value?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof SetStockAmountRequest
     */
    setToCompletelyInStock?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof SetStockAmountRequest
     */
    reset?: boolean;
}

export function SetStockAmountRequestFromJSON(json: any): SetStockAmountRequest {
    return SetStockAmountRequestFromJSONTyped(json, false);
}

export function SetStockAmountRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SetStockAmountRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'setToValue': !exists(json, 'setToValue') ? undefined : json['setToValue'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'setToCompletelyInStock': !exists(json, 'setToCompletelyInStock') ? undefined : json['setToCompletelyInStock'],
        'reset': !exists(json, 'reset') ? undefined : json['reset'],
    };
}

export function SetStockAmountRequestToJSON(value?: SetStockAmountRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'setToValue': value.setToValue,
        'value': value.value,
        'setToCompletelyInStock': value.setToCompletelyInStock,
        'reset': value.reset,
    };
}


