import React, { Component } from 'react'
import { Link } from 'react-router-dom'

import ApiBackend from '../api-backend/ApiBackend'
import DeleteModal from './DeleteModal';
import { ModalManager } from 'react-dynamic-modal';
import { withRouter } from '../../routing/withRouter';
import AppRoutes from '../../routing/AppRoutes';

import { PlanedMeals } from './PlanedMeals';
import { StartEnd } from './StartEnd';
import { Meals } from './Meals';
import { LeadingActions, SwipeableList, SwipeableListItem, SwipeAction, TrailingActions } 
  from 'react-swipeable-list';
import 'react-swipeable-list/dist/styles.css';
import bring from './Bring.png'
import { Spinner } from 'reactstrap';


class Detail extends Component {
  constructor(props) {
    super(props);

    // take the state either from the location state or set it to not loaded
    this.state = {
      loaded : !!props.location.state?.weekplan,
      weekplan : props.location.state?.weekplan || {},
    };
  }
    
  componentDidMount() {
    if(!this.state.loaded) this.update(); // initial update
  }

  handleWeekplanChanged(weekplan) { this.setState({weekplan: weekplan}); }


  async update() {
    const weekplan = (await ApiBackend.Weekplan.id({id: this.props.params.id})).weekplan;
  
    this.setState({
      loaded : true,
      weekplan : weekplan,
    });
  }
  
  openDeleteModal(weekplan) {
    ModalManager.open(<DeleteModal weekplan={weekplan} onStateUpdate={state => this.OnDeleteFinished(state)} onRequestClose={() => true}/>);
  }
  OnDeleteFinished(state) {
    this.props.navigate("/" + AppRoutes.Weekplans.routeProps.path, {state: { weekplans: state }})
  }

  

  render() {
    const { weekplan, loaded } = this.state;

    var weekplanElm = <></>
    switch(weekplan.status) {
      case "SelectMeals": 
        weekplanElm = <OpenWeekplan weekplan={weekplan} onWeekplanChanged={(weekplan) => this.handleWeekplanChanged(weekplan)} />
        break;
      case "InputStocks":
        weekplanElm = <InputStocks weekplan={weekplan} onWeekplanChanged={(weekplan) => this.handleWeekplanChanged(weekplan)} />
        break;
      case "Closed":
        weekplanElm = <ClosedWeekplan weekplan={weekplan} onWeekplanChanged={(weekplan) => this.handleWeekplanChanged(weekplan)} />
        break;

      default:
        weekplanElm = <>Undefined Status...</>
    }

    return (
      <>{ !loaded ? (<p><em>Lade Wochenplan...</em></p>) : (
        <>
          <h1>Wochenplan</h1>
          <div style={{"marginLeft": "18px"}}>{weekplanElm}</div>
          <br/><hr/>
          <div>
            <Link to="#" onClick={() => this.openDeleteModal(weekplan)}>Löschen</Link> |&nbsp;
            <Link to={"/" + AppRoutes.Weekplans.routeProps.path}>Zurück zur Liste</Link>
          </div>

        </>)}
      </>
    )
  }
}

export default withRouter(Detail)

class OpenWeekplan extends Component {
  async createShoppingList() {
    const response = (await ApiBackend.Weekplan.idCreateShoppingList({id: this.props.weekplan.id}))
    this.props.onWeekplanChanged(response.weekplan)
  }

  render() {
    const { weekplan } = this.props;

    var allMealsSelected = true;
    Object.keys(weekplan.mealTypes).map(elm => {
      const type = weekplan.mealTypes[elm];
      allMealsSelected = allMealsSelected && type.planedNumberOfMeals <= type.meals.length;
      return false;
    })
    const buttonClass = !allMealsSelected ? "btn btn-secondary" : "btn btn-success"

    return ( 
      <>
        <hr />
        <dl className="row">
          <StartEnd weekplan={weekplan} onWeekplanChanged={this.props.onWeekplanChanged}/>
          <dt className="col-sm-2">Anzahl Tage</dt>
          <dd className="col-sm-10">
            {weekplan.numberOfDays} Tage&nbsp;
            (Davon {weekplan.numberOfWeekDays} Wochentage und {weekplan.numberOfWeekendAndBankHolidaydays} Wochenend- oder Feiertage)
          </dd>
        </dl>
        <dl className="row">
          <PlanedMeals weekplan={weekplan} onWeekplanChanged={this.props.onWeekplanChanged}/>
        </dl>
        <hr />
        <Meals weekplan={weekplan} onWeekplanChanged={this.props.onWeekplanChanged} />
        <br/>
        <button  type="button" className={buttonClass} onClick={() => this.createShoppingList()}>
          Auswahl abschließen und Einkaufliste erstellen
        </button>

      </>
    )
  }
}



class InputStocks extends Component {
  constructor(props) {
    super(props)

    var state = {}
    this.props.weekplan.shoppingLists[0].entries.forEach(elm => state["amount" + elm.id] = 
      elm.isCompletelyInStock ? elm.amount : elm.isStockChecked ? elm.stockAmount : "");
    this.state = state;
  }

  async createShoppingList() {
    const response = (await ApiBackend.Weekplan.idFinaliseShoppingList({id: this.props.weekplan.id}))
    this.props.onWeekplanChanged(response.weekplan)
  }

  handleAmountChanged(amountId, event) {
    this.setState({ ["amount" + amountId]: event.target.value })
  }

  async onAmountChanged(amountId, type, amountValue) {

    const setStockAmountRequest = type === "value" ? { setToValue: true, value: amountValue} :
      (type === "completelyInStock" ? { setToCompletelyInStock: true} : 
      (type === "reset" ? { reset : true} : {})) 

    const weekplan = (await ApiBackend.Weekplan.idSetStockAmountamountId({
      id: this.props.weekplan.id, 
      amountId: amountId, 
      setStockAmountRequest: setStockAmountRequest
    })).weekplan;

    this.props.onWeekplanChanged(weekplan)

    const amount = weekplan.shoppingLists[0].entries.filter((i) => i.id === amountId)[0];
    const value = amount.isCompletelyInStock ? amount.amount :
      amount.isStockChecked ? amount.stockAmount : ""
    this.setState({ ["amount" + amount.id]: value, ["isChanged" + amount.id]: true })
  }

  render_shoppingListAmount(amount) {
    const leadingActions = () => (
      <LeadingActions style={{"marginTop": "-0.75rem"}}>
        <SwipeAction 
          onClick={() => this.onAmountChanged(amount.id, "completelyInStock")}>
          <div className="action-content" style={{"backgroundColor":"#507A3A"}}>
            <div>Komplett&nbsp;vorhanden</div>
          </div>
        </SwipeAction>
      </LeadingActions>
    );
    
    const trailingActions = () => (
      <TrailingActions>
        <SwipeAction onClick={() => this.onAmountChanged(amount.id, "value", 0)} >
          <div className="action-content" style={{"backgroundColor":"#B7793E"}}>
            <div>Nicht&nbsp;vorhanden</div>
          </div>
        </SwipeAction>
      </TrailingActions>
    );

    const amountFromWeekplan = this.props.weekplan.shoppingLists[0].entries.filter((i) => i.id === amount.id)[0];
    const stockFromWeekplan = amountFromWeekplan.isCompletelyInStock ? amountFromWeekplan.amount :
      amountFromWeekplan.isStockChecked ? amountFromWeekplan.stockAmount : ""
    const btnClass = "btn" + (this.state["amount" + amount.id] !== stockFromWeekplan ? " btn-warning" :
      (this.state["isChanged" + amount.id] ? " btn-success" : " btn-secondary"))

    return (
      <SwipeableListItem key={amount.id} className="list-group-item" style={{"background":"transparent"}}
          leadingActions={leadingActions()} 
          trailingActions={trailingActions()} >

        <div className="row" style={{"width": "100%"}}>
          <div className="col-md-4" style={{"fontWeight": "bold", "paddingBottom": "15px"}}>{amount.ingredientName}</div>
          <div className="col-md-3 col-5">Benötigt:<br/>{amount.amount} {amount.portionName}</div>
          <div className="col-md-3 col-5">Vorhanden: 
            <div className="input-group">
              <input value={this.state["amount" + amount.id]} style={{"width":"5em"}} onChange={(event) => this.handleAmountChanged(amount.id, event)} />
              <div className="input-group-append"><span className="input-group-text">{amount.portionName}</span></div>
            </div>
          </div>
          <div className="col-md-2 col-2">
            <button className={btnClass} onClick={() => this.onAmountChanged(amount.id, "value", this.state["amount" + amount.id])}>Speichern</button>
          </div>
        </div>
      </SwipeableListItem>
    )
  }

  render() {
    const noneUnchecked = this.props.weekplan.shoppingLists[0].entries.every(i => i.isStockChecked)

    return (
      <>
        <h1>Was ist bereits zu vorhanden?</h1>
        <hr />
        <h3>Zu bewerten</h3>
        <SwipeableList className="list-group">
          {this.props.weekplan.shoppingLists[0].entries.filter(i => !i.isStockChecked).map(entry => {
            return this.render_shoppingListAmount(entry)
          })}
        </SwipeableList>
        <p/>
        <h3>Bewertet</h3>
        <SwipeableList className="list-group">
          {this.props.weekplan.shoppingLists[0].entries.filter(i => i.isStockChecked).map(entry => {
            return this.render_shoppingListAmount(entry)
          })}
        </SwipeableList>
        <br/>
        <input type="submit" onClick={() => this.createShoppingList() } value="Einkaufsliste erstellen" className={noneUnchecked ? "btn btn-success" : "btn btn-primary"} />
      </>
    )
  }
}

class ClosedWeekplan extends Component {

  render() {
    const { weekplan } = this.props;

    return ( 
      <>
        <hr />
        <dl className="row">
          <StartEnd weekplan={weekplan} />
          <dt className="col-sm-2">Anzahl Tage</dt>
          <dd className="col-sm-10">
            {weekplan.numberOfDays} Tage&nbsp;
            (Davon {weekplan.numberOfWeekDays} Wochentage und {weekplan.numberOfWeekendAndBankHolidaydays} Wochenend- oder Feiertage)
          </dd>
        </dl>
        <dl className="row">
          <PlanedMeals weekplan={weekplan}/>
        </dl>
        <hr />
        <Meals weekplan={weekplan} />
        <br/>
        <hr />
        <h4>Einkauflisten</h4>
        {weekplan.shoppingLists.map((list) => 
          <Shoppinglist key={list.id} list={list} weekplanId={weekplan.id} onWeekplanChanged={this.props.onWeekplanChanged} />)}
        <br/>

      </>
    )
  }
}

class Shoppinglist extends Component {

  async addToBringList(e) {
    e.preventDefault();

    this.setState({bringLoading: true})

    var response = await ApiBackend.Weekplan.idShoppinglistshoppinglistIdAction({
      id: this.props.weekplanId, 
      shoppinglistId: this.props.list.id,
      body: "sendToBring"
    })

    this.props.onWeekplanChanged(response.weekplan)
  }

  render() {
    const list = this.props.list;
    var typeSpecific = <></>

    switch(this.props.list.target.type) {
      case "Bring":
        const isInList = list.target?.flags?.some((i) => i === "InBringList");
        typeSpecific = <>
          <a href="/" onClick={(e) => this.addToBringList(e)} className={"btn mb-3 " + (isInList ? "btn-success" : "btn-primary")} style={{fontWeight:700}}><img src={bring} alt="" style={{height: "34px"}} /> Add to Bring! List
            {isInList ? <>&nbsp;&nbsp;✓</> : 
              (!this.state?.bringLoading ? <></> : <>&nbsp;&nbsp;&nbsp;<Spinner style={{"width":"25px", "height": "25px"}} animation="border" variant="light" /></>)}</a>
        </>
        break;
      default: typeSpecific = <></>; break;

    }

    return (
      <div className="col-lg-9">
        <h6 style={{"fontWeight":700}}>{this.props.list.name} (Target type: {this.props.list.target.type})</h6>
        {typeSpecific}
        <ul className="list-group">
          {list.entries.filter(i => i.isCompletelyInStock !== true && (!i.isStockChecked || i.stockAmount < i.amount)).map((entry) => {
            const amountToBuy = entry.isStockChecked ? entry.amount : entry.amount - entry.stockAmount;


            return(<li className="list-group-item" key={entry.id}>
              {entry.ingredientName}: {amountToBuy} {entry.portionName}
            </li>);
          })}
        </ul>
      </div>
    )
  }  
}
