/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IngredientDetailedNoIds,
    IngredientDetailedNoIdsFromJSON,
    IngredientDetailedNoIdsFromJSONTyped,
    IngredientDetailedNoIdsToJSON,
} from './';

/**
 * 
 * @export
 * @interface CreateIngredientRequest
 */
export interface CreateIngredientRequest {
    /**
     * 
     * @type {IngredientDetailedNoIds}
     * @memberof CreateIngredientRequest
     */
    ingredient: IngredientDetailedNoIds;
}

export function CreateIngredientRequestFromJSON(json: any): CreateIngredientRequest {
    return CreateIngredientRequestFromJSONTyped(json, false);
}

export function CreateIngredientRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateIngredientRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ingredient': IngredientDetailedNoIdsFromJSON(json['ingredient']),
    };
}

export function CreateIngredientRequestToJSON(value?: CreateIngredientRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ingredient': IngredientDetailedNoIdsToJSON(value.ingredient),
    };
}


