import React, { Component } from 'react';
import apiBackend from '../api-backend/ApiBackend';
import EditOrCreate from './EditOrCreate';
import { generatePath } from "react-router";
import { createFullPath } from '../../routing/AppRoutes';
import { withRouter } from '../../routing/withRouter';

class Create extends Component {
  async handleSafeEvent(weekplan) {
    var response = await apiBackend.Weekplan.create({createWeekplanRequest: weekplan});
    var forewardPath = generatePath(createFullPath("Weekplan","Detail"), {id: response.weekplan.id})

    this.props.navigate(forewardPath, {state: {weekplan: response.weekplan }});
  }
  
  render() { return (
    <>
      <h1>Wochenplan erstellen</h1>
      <hr/>
      <EditOrCreate 
        onSaveEvent={(weekplan) => this.handleSafeEvent(weekplan)} 
        saveButton="Erstellen" />
    </>); }
}

export default withRouter(Create)
