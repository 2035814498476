import React, { Component } from 'react';
import { EditStepList } from './EditStepList';

export class EditSubchapter extends Component {

  onSubchapterChanged(subchapter) {
    if (!!this.props.onSubchapterChanged)
      this.props.onSubchapterChanged(subchapter);
  }
  onDelete() {
    if (!!this.props.onDelete)
      this.props.onDelete();
  }


  handleStepsChanged(steps) {
    var subchapter = this.props.subchapter;
    subchapter.steps = steps;
    this.onSubchapterChanged(subchapter);
  }
  handleHeadlineChange(event) {
    var subchapter = this.props.subchapter;
    subchapter.headline = event.target.value;
    this.onSubchapterChanged(subchapter);
  }


  render() {
    return (
      <div className="jumbotron" style={{ padding: "1rem" }}>
        <input className="form-control" style={{ fontSize: "xx-large" }} value={this.props.subchapter.headline} onChange={(event) => this.handleHeadlineChange(event)} />
        <span className="text-danger">{(!!this.props.validResult?.headline) ? this.props.validResult.headline : ""}</span>
        <EditStepList
          steps={(!this.props.subchapter.steps) ? [] : this.props.subchapter.steps}
          onStepsChanged={(steps) => this.handleStepsChanged(steps)}
          validResult={(!!this.props.validResult.steps) ? this.props.validResult.steps : {}}
          ingredients={this.props.ingredients} />
        <span className="text-danger field-validation-valid">{(!!this.props.validResult.general) ? <>{this.props.validResult.general}<p /></> : ""}</span>
        <br />
        <input type="submit" value="Del" className="btn btn-danger" onClick={() => this.onDelete()} />
      </div>

    );
  }
}
