/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    AddMealInWeekplanRequest,
    AddMealInWeekplanRequestFromJSON,
    AddMealInWeekplanRequestToJSON,
    ChangedPlanedMealsInWeekplanRequest,
    ChangedPlanedMealsInWeekplanRequestFromJSON,
    ChangedPlanedMealsInWeekplanRequestToJSON,
    CreateWeekplanRequest,
    CreateWeekplanRequestFromJSON,
    CreateWeekplanRequestToJSON,
    SetStockAmountRequest,
    SetStockAmountRequestFromJSON,
    SetStockAmountRequestToJSON,
    UpdateWeekplanRequest,
    UpdateWeekplanRequestFromJSON,
    UpdateWeekplanRequestToJSON,
    WeekplanDetailedResponse,
    WeekplanDetailedResponseFromJSON,
    WeekplanDetailedResponseToJSON,
    WeekplanListResponse,
    WeekplanListResponseFromJSON,
    WeekplanListResponseToJSON,
} from '../models';

export interface WeekplanApiCreateRequest {
    createWeekplanRequest?: CreateWeekplanRequest;
}

export interface WeekplanApiIdRequest {
    id: number;
}

export interface WeekplanApiIdChangedPlanedMealsRequest {
    id: number;
    changedPlanedMealsInWeekplanRequest?: ChangedPlanedMealsInWeekplanRequest;
}

export interface WeekplanApiIdCreateShoppingListRequest {
    id: number;
}

export interface WeekplanApiIdDeleteRequest {
    id: number;
}

export interface WeekplanApiIdFinaliseShoppingListRequest {
    id: number;
}

export interface WeekplanApiIdRemoveMealmealIdRequest {
    id: number;
    mealId: number;
}

export interface WeekplanApiIdSetStockAmountamountIdRequest {
    id: number;
    amountId: number;
    setStockAmountRequest?: SetStockAmountRequest;
}

export interface WeekplanApiIdShoppinglistshoppinglistIdActionRequest {
    id: number;
    shoppinglistId: number;
    body?: string;
}

export interface WeekplanApiIdUpdateRequest {
    id: number;
    updateWeekplanRequest?: UpdateWeekplanRequest;
}

export interface WeekplanApiIdmealTypeAddMealRequest {
    id: number;
    mealType: string;
    addMealInWeekplanRequest?: AddMealInWeekplanRequest;
}

/**
 * 
 */
export class WeekplanApi extends runtime.BaseAPI {

    /**
     */
    async createRaw(requestParameters: WeekplanApiCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateWeekplanRequestToJSON(requestParameters.createWeekplanRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: WeekplanApiCreateRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idRaw(requestParameters: WeekplanApiIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling id.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async id(requestParameters: WeekplanApiIdRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idChangedPlanedMealsRaw(requestParameters: WeekplanApiIdChangedPlanedMealsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idChangedPlanedMeals.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/changedPlanedMeals`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangedPlanedMealsInWeekplanRequestToJSON(requestParameters.changedPlanedMealsInWeekplanRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idChangedPlanedMeals(requestParameters: WeekplanApiIdChangedPlanedMealsRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idChangedPlanedMealsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idCreateShoppingListRaw(requestParameters: WeekplanApiIdCreateShoppingListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idCreateShoppingList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/createShoppingList`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idCreateShoppingList(requestParameters: WeekplanApiIdCreateShoppingListRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idCreateShoppingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idDeleteRaw(requestParameters: WeekplanApiIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanListResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanListResponseFromJSON(jsonValue));
    }

    /**
     */
    async idDelete(requestParameters: WeekplanApiIdDeleteRequest, initOverrides?: RequestInit): Promise<WeekplanListResponse> {
        const response = await this.idDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idFinaliseShoppingListRaw(requestParameters: WeekplanApiIdFinaliseShoppingListRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idFinaliseShoppingList.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/finaliseShoppingList`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idFinaliseShoppingList(requestParameters: WeekplanApiIdFinaliseShoppingListRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idFinaliseShoppingListRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idRemoveMealmealIdRaw(requestParameters: WeekplanApiIdRemoveMealmealIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idRemoveMealmealId.');
        }

        if (requestParameters.mealId === null || requestParameters.mealId === undefined) {
            throw new runtime.RequiredError('mealId','Required parameter requestParameters.mealId was null or undefined when calling idRemoveMealmealId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/removeMeal/{mealId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mealId"}}`, encodeURIComponent(String(requestParameters.mealId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idRemoveMealmealId(requestParameters: WeekplanApiIdRemoveMealmealIdRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idRemoveMealmealIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idSetStockAmountamountIdRaw(requestParameters: WeekplanApiIdSetStockAmountamountIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idSetStockAmountamountId.');
        }

        if (requestParameters.amountId === null || requestParameters.amountId === undefined) {
            throw new runtime.RequiredError('amountId','Required parameter requestParameters.amountId was null or undefined when calling idSetStockAmountamountId.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/setStockAmount/{amountId}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"amountId"}}`, encodeURIComponent(String(requestParameters.amountId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SetStockAmountRequestToJSON(requestParameters.setStockAmountRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idSetStockAmountamountId(requestParameters: WeekplanApiIdSetStockAmountamountIdRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idSetStockAmountamountIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idShoppinglistshoppinglistIdActionRaw(requestParameters: WeekplanApiIdShoppinglistshoppinglistIdActionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idShoppinglistshoppinglistIdAction.');
        }

        if (requestParameters.shoppinglistId === null || requestParameters.shoppinglistId === undefined) {
            throw new runtime.RequiredError('shoppinglistId','Required parameter requestParameters.shoppinglistId was null or undefined when calling idShoppinglistshoppinglistIdAction.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/shoppinglist/{shoppinglistId}/action`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"shoppinglistId"}}`, encodeURIComponent(String(requestParameters.shoppinglistId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idShoppinglistshoppinglistIdAction(requestParameters: WeekplanApiIdShoppinglistshoppinglistIdActionRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idShoppinglistshoppinglistIdActionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idUpdateRaw(requestParameters: WeekplanApiIdUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateWeekplanRequestToJSON(requestParameters.updateWeekplanRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idUpdate(requestParameters: WeekplanApiIdUpdateRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idmealTypeAddMealRaw(requestParameters: WeekplanApiIdmealTypeAddMealRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<WeekplanDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idmealTypeAddMeal.');
        }

        if (requestParameters.mealType === null || requestParameters.mealType === undefined) {
            throw new runtime.RequiredError('mealType','Required parameter requestParameters.mealType was null or undefined when calling idmealTypeAddMeal.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Weekplan/{id}/{mealType}/addMeal`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))).replace(`{${"mealType"}}`, encodeURIComponent(String(requestParameters.mealType))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddMealInWeekplanRequestToJSON(requestParameters.addMealInWeekplanRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => WeekplanDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idmealTypeAddMeal(requestParameters: WeekplanApiIdmealTypeAddMealRequest, initOverrides?: RequestInit): Promise<WeekplanDetailedResponse> {
        const response = await this.idmealTypeAddMealRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
