import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Component } from 'react'

export class EditOrCreate extends Component {
  constructor(props) {
    super(props);

    const today = new Date(); today.setHours(0,0,0,0);
    const inAWeek = new Date(today); inAWeek.setDate(today.getDate() + 7);

    this.state = {
      weekplan: !!props.weekplan ? 
        {
          start: this.date2string(props.weekplan.start),
          end: this.date2string(props.weekplan.end)
        } : {
          start : this.date2string(today), 
          end : this.date2string(inAWeek), 
        },
      validResult: {details: {}, valid: true},
      validationActive: false
    }

    this.isStartOnly = props.type === "startOnly";
    this.isEndOnly = props.type === "endOnly";
  }

  isStartOnly = false;
  isEndOnly = false;
  
  onWeekplanChanged = (weekplan) => {
    if(this.state.validationActive) {
      var result = this.validateWeekplan();

      this.setState({
        weekplan: weekplan,
        validResult: result.details,
        validationActive: !result.valid, //deactivate validation once all faults are away
      })
    } else {
      this.setState({weekplan: weekplan});
    }
  }
  
  validateWeekplan = () => {
    var weekplan = this.state.weekplan;
    var result = {details: [], valid: true};
    const aWeekAgo = new Date(); aWeekAgo.setDate(aWeekAgo.getDate() - 7);

    const start = this.string2Date(weekplan.start);
    const end = this.string2Date(weekplan.end);

    /* weekplan.start */
    if(isNaN(start)) {
      result.valid = false;
      result.details.start = "Kein korrektes Datum";
    }
    /* weekplan.start */
    if(isNaN(end)) {
      result.valid = false;
      result.details.end = "Kein korrektes Datum";
    }

    /* start befor end */
    if(result.valid && end < start) {
      result.valid = false;
      result.details.end = "Das Ende muss nach dem Start Datum sein.";
    }

    /* start befor end */
    if(result.valid && start < aWeekAgo) {
      result.valid = false;
      result.details.start = "Das Start soll nicht länger als eine Woche in der Vergangenheit liegen.";
    }

    if(result.valid && (end-start)/86400000 > 14) {
      result.valid = false;
      result.details.end = "Die Dauer soll nicht mehr als 14 Tage sein.";
    }

    return result;    
  }

  handleSave() {
    var result = this.validateWeekplan();

    this.setState({
      validResult: result.details,
      validationActive: true,
    })

    const weekplan = {
      start: this.string2Date(this.state.weekplan.start),
      end: this.string2Date(this.state.weekplan.end)
    }

    if(result.valid && !!this.props.onSaveEvent) this.props.onSaveEvent(weekplan);
  }
  handleCancel() {
    if(!!this.props.onCancelEvent) this.props.onCancelEvent();
  }

  handleStartChange(event) {
    var weekplan = this.state.weekplan
    weekplan.start = event.target.value;
    this.onWeekplanChanged(weekplan);
  }
  handleEndChange(event) {
    var weekplan = this.state.weekplan
    weekplan.end = event.target.value;
    this.onWeekplanChanged(weekplan);
  }

  date2string(date) {
    // Get year, month, and day part from the date
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = year + "-" + month + "-" + day;
    return formattedDate;
  }
  string2Date(dateStr) {
    var parts = dateStr.split("-")
    return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2]))
  }


  render() {
    const {weekplan} = this.state;

    const startInput = <input className="form-control" type="date" data-val="true" value={weekplan.start} onChange={event => this.handleStartChange(event)} />
    const endInput = <input className="form-control" type="date" data-val="true" value={weekplan.end} onChange={event => this.handleEndChange(event)} />
    const submitButton = <input type="submit" value={this.props.saveButton} className="btn btn-primary" onClick={() => this.handleSave()} />  
    const cancelButton = <button type="button" className="btn btn-danger" onClick={() => this.handleCancel()} style={{"marginLeft":"1em"}} ><FontAwesomeIcon icon={faTimes} /></button>
    
    if(this.isStartOnly || this.isEndOnly) {
      return (
        <>
          <div className="row">
            <div className="col-md-4">{this.isStartOnly ? startInput : endInput}</div>
            <div className="col-md-4">{submitButton}{cancelButton}</div>
          </div>
          {this.state.validResult.valid ? <></> :
            <span className="text-danger field-validation-valid">{this.state.validResult?.start || this.state.validResult.end }</span>
          }
        </>
      )
    }

    return (
      <>
        <div className="row">
          <div className="col-md-6">
              
              <div className="form-group">
                <label className="control-label">Start</label>
                {startInput}
                <span className="text-danger field-validation-valid">{(!!this.state.validResult?.start) ? this.state.validResult.start : ""}</span>
              </div>
              <div className="form-group">
                <label className="control-label">End</label>
                {endInput}
                <span className="text-danger field-validation-valid">{(!!this.state.validResult?.end) ? this.state.validResult.end : ""}</span>
              </div>
              <div className="form-group">
                {submitButton}
              </div>
          </div>
        </div>

      </>

    )
  }
}

export default EditOrCreate
