/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    MealTypeDetailed,
    MealTypeDetailedFromJSON,
    MealTypeDetailedFromJSONTyped,
    MealTypeDetailedToJSON,
    ShoppingListDetailed,
    ShoppingListDetailedFromJSON,
    ShoppingListDetailedFromJSONTyped,
    ShoppingListDetailedToJSON,
} from './';

/**
 * 
 * @export
 * @interface WeekplanDetailed
 */
export interface WeekplanDetailed {
    /**
     * 
     * @type {Date}
     * @memberof WeekplanDetailed
     */
    start?: Date;
    /**
     * 
     * @type {Date}
     * @memberof WeekplanDetailed
     */
    end?: Date;
    /**
     * 
     * @type {number}
     * @memberof WeekplanDetailed
     */
    id?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WeekplanDetailed
     */
    isArchived?: boolean;
    /**
     * 
     * @type {Array<ShoppingListDetailed>}
     * @memberof WeekplanDetailed
     */
    shoppingLists?: Array<ShoppingListDetailed> | null;
    /**
     * 
     * @type {number}
     * @memberof WeekplanDetailed
     */
    numberOfDays?: number;
    /**
     * 
     * @type {number}
     * @memberof WeekplanDetailed
     */
    numberOfWeekDays?: number;
    /**
     * 
     * @type {number}
     * @memberof WeekplanDetailed
     */
    numberOfWeekendAndBankHolidaydays?: number;
    /**
     * 
     * @type {{ [key: string]: MealTypeDetailed; }}
     * @memberof WeekplanDetailed
     */
    mealTypes?: { [key: string]: MealTypeDetailed; } | null;
    /**
     * 
     * @type {string}
     * @memberof WeekplanDetailed
     */
    readonly status?: string | null;
}

export function WeekplanDetailedFromJSON(json: any): WeekplanDetailed {
    return WeekplanDetailedFromJSONTyped(json, false);
}

export function WeekplanDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekplanDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'start': !exists(json, 'start') ? undefined : (new Date(json['start'])),
        'end': !exists(json, 'end') ? undefined : (new Date(json['end'])),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'isArchived': !exists(json, 'isArchived') ? undefined : json['isArchived'],
        'shoppingLists': !exists(json, 'shoppingLists') ? undefined : (json['shoppingLists'] === null ? null : (json['shoppingLists'] as Array<any>).map(ShoppingListDetailedFromJSON)),
        'numberOfDays': !exists(json, 'numberOfDays') ? undefined : json['numberOfDays'],
        'numberOfWeekDays': !exists(json, 'numberOfWeekDays') ? undefined : json['numberOfWeekDays'],
        'numberOfWeekendAndBankHolidaydays': !exists(json, 'numberOfWeekendAndBankHolidaydays') ? undefined : json['numberOfWeekendAndBankHolidaydays'],
        'mealTypes': !exists(json, 'mealTypes') ? undefined : (json['mealTypes'] === null ? null : mapValues(json['mealTypes'], MealTypeDetailedFromJSON)),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function WeekplanDetailedToJSON(value?: WeekplanDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'start': value.start === undefined ? undefined : (value.start.toISOString()),
        'end': value.end === undefined ? undefined : (value.end.toISOString()),
        'id': value.id,
        'isArchived': value.isArchived,
        'shoppingLists': value.shoppingLists === undefined ? undefined : (value.shoppingLists === null ? null : (value.shoppingLists as Array<any>).map(ShoppingListDetailedToJSON)),
        'numberOfDays': value.numberOfDays,
        'numberOfWeekDays': value.numberOfWeekDays,
        'numberOfWeekendAndBankHolidaydays': value.numberOfWeekendAndBankHolidaydays,
        'mealTypes': value.mealTypes === undefined ? undefined : (value.mealTypes === null ? null : mapValues(value.mealTypes, MealTypeDetailedToJSON)),
    };
}


