/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeStepDetailed,
    RecipeStepDetailedFromJSON,
    RecipeStepDetailedFromJSONTyped,
    RecipeStepDetailedToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeSubchapterDetailed
 */
export interface RecipeSubchapterDetailed {
    /**
     * 
     * @type {number}
     * @memberof RecipeSubchapterDetailed
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecipeSubchapterDetailed
     */
    headline: string;
    /**
     * 
     * @type {number}
     * @memberof RecipeSubchapterDetailed
     */
    order: number;
    /**
     * 
     * @type {Array<RecipeStepDetailed>}
     * @memberof RecipeSubchapterDetailed
     */
    steps?: Array<RecipeStepDetailed> | null;
}

export function RecipeSubchapterDetailedFromJSON(json: any): RecipeSubchapterDetailed {
    return RecipeSubchapterDetailedFromJSONTyped(json, false);
}

export function RecipeSubchapterDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeSubchapterDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'headline': json['headline'],
        'order': json['order'],
        'steps': !exists(json, 'steps') ? undefined : (json['steps'] === null ? null : (json['steps'] as Array<any>).map(RecipeStepDetailedFromJSON)),
    };
}

export function RecipeSubchapterDetailedToJSON(value?: RecipeSubchapterDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'headline': value.headline,
        'order': value.order,
        'steps': value.steps === undefined ? undefined : (value.steps === null ? null : (value.steps as Array<any>).map(RecipeStepDetailedToJSON)),
    };
}


