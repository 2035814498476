import React, { Component } from 'react';
import { EditStep } from './EditStep';

export class EditStepList extends Component {
  constructor(props) {
    super(props);

    if (!!this.props.steps && this.props.steps.length > 0) {
      this.nextOrder = Math.max(...this.props.steps.map(o => (!o.order) ? 0 : o.order)) + 1;
      this.nextNewId = Math.max(...this.props.steps.map(o => (!o.newId) ? 0 : o.newId)) + 1;
    }
  }

  onStepsChanged(steps) {
    if (!!this.props.onStepsChanged) 
      this.props.onStepsChanged(steps);
  }

  nextOrder = 0;
  nextNewId = 0;
  getNextOrder() {
    var returnVal = this.nextOrder;
    this.nextOrder = this.nextOrder + 1;
    return returnVal;
  }
  getNextNewId() {
    var returnVal = this.nextNewId;
    this.nextNewId = this.nextNewId + 1;
    return returnVal;
  }


  handleStepChanged(i, step) {
    var steps = this.props.steps;
    steps[i] = step;
    this.onStepsChanged(steps);
  }
  handleAddStepToTheEnd() {
    var steps = this.props.steps;
    steps.push({ order: this.getNextOrder(), newId: this.getNextNewId() });
    this.onStepsChanged(steps);
  }
  handleDeleteStep(i) {
    var steps = this.props.steps;
    steps.splice(i, 1);
    this.onStepsChanged(steps);
  }


  render() {
    return (
      <table className="table table-striped table-striped-white" style={{ margin: "0pt" }}>
        <tbody>
          {this.props.steps.map((step, i) => <EditStep step={step}
            key={(!!step.id) ? step.id : "new_" + step.newId}
            onDelete={() => this.handleDeleteStep(i)}
            onStepChanged={(step) => this.handleStepChanged(i, step)} 
            validResult={(!!this.props.validResult[i]) ? this.props.validResult[i] : {}}
            ingredients={this.props.ingredients} />)}

          <tr><td><input type="submit" value="Schritt hinzufügen" className="btn btn-primary" onClick={() => this.handleAddStepToTheEnd()} /></td></tr>
        </tbody>
      </table>
    );
  }
}
