import React, { Component } from 'react'
import { EditPortions } from './EditPortions';

export class EditOrCreate extends Component {
    
  state = {
    ingredient: this.props.ingredient || {
      name: "",
      portions: [{isApproximal: false, isDefault: true, name: "g", newId: 0, weight: 1}]
    },
    validResult: {details: {}, valid: true},
    validationActive: false,
  }
  
  onIngredientChanged = (ingredient) => {
    if(this.state.validationActive) {
      var result = this.validateIngredient();

      this.setState({
        ingredient: ingredient,
        validResult: result.details,
        validationActive: !result.valid, //deactivate validation once all faults are away
      })
    } else {
      this.setState({ingredient: ingredient});
    }
  }
  validateIngredient = () => {
    var ingredient = this.state.ingredient;
    var result = {details: {portions : []}, valid: true};

    /* ingredient.name */
    if(!ingredient.name || ingredient.name == null | ingredient.name === "") {
      result.valid = false;
      result.details.name = "Ein Name muss angegeben werden.";
    }

    /* ingredient.portions */
    var numberOfPortions = (!ingredient.portions) ? 0 : ingredient.portions.length;
    if(numberOfPortions <= 0) {
      result.valid = false;
      result.details.general = "Eine Zutat  muss mindestens eine Portion enthalten";
    }
    if(!!ingredient.portions) ingredient.portions.map(portion => {
      var subResult = this.validatePortion(portion);
      result.valid = result.valid && subResult.valid;
      result.details.portions.push(subResult.details);
      return [];
    });

    return result;
  }
  validatePortion(portion) {
    var result = {details: {}, valid: true};

    /* portion.name */
    if(!portion.name || portion.name == null || portion.name === "") {
      result.valid = false;
      result.details.name = "Eine Portion muss einen Namen haben.";
    }

    /* portion.weight */
    if(!portion.weight || portion.weight == null || isNaN(portion.weight)) {
      result.valid = false;
      result.details.weight = "Das Gewicht muss eine Zahl sein";
    }

    return result;
  }


  handleSave() {
    var result = this.validateIngredient();

    this.setState({
      validResult: result.details,
      validationActive: !result.valid, //activate validation in case of faults
    })

    if(result.valid && !!this.props.onSaveEvent) this.props.onSaveEvent(this.state.ingredient);
  }

  handleNameChange(event) {
    var ingredient = this.state.ingredient
    ingredient.name = event.target.value;
    this.onIngredientChanged(ingredient);
  }
  handlePortionsChange(portions) {
    var ingredient = this.state.ingredient
    ingredient.portions = portions;
    this.onIngredientChanged(ingredient);
  }


  render() {
    const {ingredient} = this.state;

    return (
      <>
        <h1>{this.props.title}</h1>
        <hr/>
        <div className="row">
          <div className="col-md-12">
            <dl className="row">
              <dt className="col-sm-4">Name</dt>
              <dd className="col-sm-8">
                <input className="form-control" value={ingredient.name} onChange={event => this.handleNameChange(event)} />
                <span className="text-danger field-validation-valid">{(!!this.state.validResult.name) ? this.state.validResult.name : ""}</span>
              </dd>
            </dl>
          </div>
          <div className="col-md-12">
            <EditPortions portions={ingredient.portions}
              onPortionsChanged={(portions) => this.handlePortionsChange(portions)}
              validResult={this.state.validResult.portions ?? []}
              />
          </div>
          <div className="col-md-12">
            <span className="text-danger field-validation-valid">{(!!this.state.validResult.general) ? <><p/>{this.state.validResult.general}</> : ""}</span>
          </div>
        </div>
        <hr/>
        <input type="submit" value={this.props.saveButton} className="btn btn-primary" onClick={() => this.handleSave()} />
      </>

    )
  }
}

export default EditOrCreate
