/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IngredientMeta,
    IngredientMetaFromJSON,
    IngredientMetaFromJSONTyped,
    IngredientMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface IngredientMetaListResponse
 */
export interface IngredientMetaListResponse {
    /**
     * 
     * @type {Array<IngredientMeta>}
     * @memberof IngredientMetaListResponse
     */
    ingredients?: Array<IngredientMeta> | null;
}

export function IngredientMetaListResponseFromJSON(json: any): IngredientMetaListResponse {
    return IngredientMetaListResponseFromJSONTyped(json, false);
}

export function IngredientMetaListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngredientMetaListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ingredients': !exists(json, 'ingredients') ? undefined : (json['ingredients'] === null ? null : (json['ingredients'] as Array<any>).map(IngredientMetaFromJSON)),
    };
}

export function IngredientMetaListResponseToJSON(value?: IngredientMetaListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ingredients': value.ingredients === undefined ? undefined : (value.ingredients === null ? null : (value.ingredients as Array<any>).map(IngredientMetaToJSON)),
    };
}


