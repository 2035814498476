import React,{Component} from 'react';
import { Modal,ModalManager,Effect} from 'react-dynamic-modal';
import apiBackend from '../api-backend/ApiBackend';
 
class DeleteModal extends Component{

   async onDelete(id) {
      var weekplans = await apiBackend.Weekplan.idDelete({id: id})

      this.props.onStateUpdate(weekplans)
      ModalManager.close();
   }


   render(){
      const { weekplan, onRequestClose } = this.props;
      
      return (
         <Modal
            onRequestClose={onRequestClose} effect={Effect.ScaleUp}>

            <div className="modal-header">
               <h4 className="modal-title">Soll der Wochenplan gelöscht werden?</h4>
            </div>
            <div className="modal-footer">
               <button type="button" className="btn btn-danger" onClick={() => this.onDelete(weekplan.id)}>Löschen</button>
               <button type="button" className="btn btn-default" onClick={ModalManager.close}>Schließen</button>
            </div>
         </Modal>
      );
   }
}

export default DeleteModal