/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShoppingListAmountSummary
 */
export interface ShoppingListAmountSummary {
    /**
     * 
     * @type {number}
     * @memberof ShoppingListAmountSummary
     */
    id?: number;
    /**
     * 
     * @type {number}
     * @memberof ShoppingListAmountSummary
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingListAmountSummary
     */
    portionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShoppingListAmountSummary
     */
    ingredientName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingListAmountSummary
     */
    isCompletelyInStock?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ShoppingListAmountSummary
     */
    stockAmount?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingListAmountSummary
     */
    isStockChecked?: boolean;
}

export function ShoppingListAmountSummaryFromJSON(json: any): ShoppingListAmountSummary {
    return ShoppingListAmountSummaryFromJSONTyped(json, false);
}

export function ShoppingListAmountSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingListAmountSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'portionName': !exists(json, 'portionName') ? undefined : json['portionName'],
        'ingredientName': !exists(json, 'ingredientName') ? undefined : json['ingredientName'],
        'isCompletelyInStock': !exists(json, 'isCompletelyInStock') ? undefined : json['isCompletelyInStock'],
        'stockAmount': !exists(json, 'stockAmount') ? undefined : json['stockAmount'],
        'isStockChecked': !exists(json, 'isStockChecked') ? undefined : json['isStockChecked'],
    };
}

export function ShoppingListAmountSummaryToJSON(value?: ShoppingListAmountSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'portionName': value.portionName,
        'ingredientName': value.ingredientName,
        'isCompletelyInStock': value.isCompletelyInStock,
        'stockAmount': value.stockAmount,
        'isStockChecked': value.isStockChecked,
    };
}


