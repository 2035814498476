/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortionDetailed
 */
export interface PortionDetailed {
    /**
     * 
     * @type {number}
     * @memberof PortionDetailed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PortionDetailed
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PortionDetailed
     */
    isDefault?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PortionDetailed
     */
    isApproximal?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PortionDetailed
     */
    weight?: number;
}

export function PortionDetailedFromJSON(json: any): PortionDetailed {
    return PortionDetailedFromJSONTyped(json, false);
}

export function PortionDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortionDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
        'isApproximal': !exists(json, 'isApproximal') ? undefined : json['isApproximal'],
        'weight': !exists(json, 'weight') ? undefined : json['weight'],
    };
}

export function PortionDetailedToJSON(value?: PortionDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'isDefault': value.isDefault,
        'isApproximal': value.isApproximal,
        'weight': value.weight,
    };
}


