/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ShoppingListTargetDetailed
 */
export interface ShoppingListTargetDetailed {
    /**
     * 
     * @type {number}
     * @memberof ShoppingListTargetDetailed
     */
    readonly id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingListTargetDetailed
     */
    readonly name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ShoppingListTargetDetailed
     */
    readonly isDefault?: boolean;
}

export function ShoppingListTargetDetailedFromJSON(json: any): ShoppingListTargetDetailed {
    return ShoppingListTargetDetailedFromJSONTyped(json, false);
}

export function ShoppingListTargetDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingListTargetDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
    };
}

export function ShoppingListTargetDetailedToJSON(value?: ShoppingListTargetDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
    };
}


