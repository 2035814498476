/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeMeta,
    RecipeMetaFromJSON,
    RecipeMetaFromJSONTyped,
    RecipeMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeMetaWithVariants
 */
export interface RecipeMetaWithVariants {
    /**
     * 
     * @type {number}
     * @memberof RecipeMetaWithVariants
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecipeMetaWithVariants
     */
    name: string;
    /**
     * 
     * @type {boolean}
     * @memberof RecipeMetaWithVariants
     */
    hasVariants?: boolean;
    /**
     * 
     * @type {Array<RecipeMeta>}
     * @memberof RecipeMetaWithVariants
     */
    variants?: Array<RecipeMeta> | null;
}

export function RecipeMetaWithVariantsFromJSON(json: any): RecipeMetaWithVariants {
    return RecipeMetaWithVariantsFromJSONTyped(json, false);
}

export function RecipeMetaWithVariantsFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeMetaWithVariants {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'hasVariants': !exists(json, 'hasVariants') ? undefined : json['hasVariants'],
        'variants': !exists(json, 'variants') ? undefined : (json['variants'] === null ? null : (json['variants'] as Array<any>).map(RecipeMetaFromJSON)),
    };
}

export function RecipeMetaWithVariantsToJSON(value?: RecipeMetaWithVariants | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'hasVariants': value.hasVariants,
        'variants': value.variants === undefined ? undefined : (value.variants === null ? null : (value.variants as Array<any>).map(RecipeMetaToJSON)),
    };
}


