import React, { Component } from 'react'
import apiBackend from '../api-backend/ApiBackend';
import { EditStepList } from './EditStepList';
import { EditSubchapterList } from './EditSubchapterList';

export class EditOrCreate extends Component {
  async componentDidMount() {
    var response = await apiBackend.Ingredients.getSummaryList();
    this.setState({ingredients: response.ingredients})
  }
  
  state = {
    recipe: this.props.recipe || {
      name : "" , 
      servings: 2, 
      steps: [
        {order: 0, newId: 0},
      ]},
    validResult: {details: {}, valid: true},
    validationActive: false,
    ingredients: "loading",
  }
  
  onRecipeChanged = (recipe) => {
    if(this.state.validationActive) {
      var result = this.validateRecipe();

      this.setState({
        recipe: recipe,
        validResult: result.details,
        validationActive: !result.valid, //deactivate validation once all faults are away
      })
    } else {
      this.setState({recipe: recipe});
    }
  }
  validateRecipe = () => {
    var recipe = this.state.recipe;
    var result = {details: {subchapters : [], steps: []}, valid: true};

    /* recipe.name */
    if(!recipe.name || recipe.name == null | recipe.name === "") {
      result.valid = false;
      result.details.name = "Ein Name muss angegeben werden.";
    }
    /* recipe.servings */
    if(!recipe.servings || recipe.servings == null | recipe.servings <= 0) {
      result.valid = false;
      result.details.servings = "Die Anzahl der Portionen muss größer als 0 sein";
    }
    /* recipe.steps or recipe.subchapter */
    var numberOfSteps = (!recipe.steps) ? 0 : recipe.steps.length;
    var numberOfSubchapters = (!recipe.subchapters) ? 0 : recipe.subchapters.length;
    if(numberOfSteps + numberOfSubchapters <= 0) {
      result.valid = false;
      result.details.general = "Ein Rezept muss mindestens einen Schritt oder ein Unterkapitel enthalten";
    }

    /* recipe.subchapters */
    if(!!recipe.subchapters) recipe.subchapters.map(subchapter => {
      var subResult = this.validateSubchapter(subchapter);
      result.valid = result.valid && subResult.valid;
      result.details.subchapters.push(subResult.details);
      return [];
    });

    return result;    
  }
  validateSubchapter(subchapter) {
    var result = {details: {}, valid: true};

    /* subchapter.headline */
    if(!subchapter.headline || subchapter.headline == null | subchapter.headline === "") {
      result.valid = false;
      result.details.headline = "Ein Unterkapitel muss eine Überschrift haben.";
    }

    /* subchapter.steps */
    var numberOfSteps = (!subchapter.steps) ? 0 : subchapter.steps.length;
    if(numberOfSteps <= 0) {
      result.valid = false;
      result.details.general = "Ein Unterkapitel muss mindestens einen Schritt enthalten";
    }


    return result;
  }



  handleSave() {
    var result = this.validateRecipe();

    this.setState({
      validResult: result.details,
      validationActive: true,
    })

    if(result.valid && !!this.props.onSaveEvent) this.props.onSaveEvent(this.state.recipe);
  }

  handleNameChange(event) {
    var recipe = this.state.recipe
    recipe.name = event.target.value;
    this.onRecipeChanged(recipe);
  }
  handlePortionsChange(event) {
    var recipe = this.state.recipe
    recipe.servings = event.target.value;
    this.onRecipeChanged(recipe);
  }
  handleStepsChanged(steps) {
    var recipe = this.state.recipe
    recipe.steps = steps;
    this.onRecipeChanged(recipe);
  }
  handleSubchapterChanged(subchapters) {
    var recipe = this.state.recipe
    recipe.subchapters = subchapters;
    this.onRecipeChanged(recipe);
  }


  render() {
    const {recipe} = this.state;
    return (
      <>
        <h1>{this.props.title}</h1>
        <hr/>
        {!recipe.baseRecipeName ? <></> : <h2>Basisrezept: {recipe.baseRecipeName}</h2>}
        <div className="row">
          <div className="col-md-12">
            <dl className="row">
              <dt className="col-sm-4">Name</dt>
              <dd className="col-sm-8">
                <input className="form-control" value={recipe.name} onChange={event => this.handleNameChange(event)} />
                <span className="text-danger field-validation-valid">{(!!this.state.validResult.name) ? this.state.validResult.name : ""}</span>
              </dd>
              <dt className="col-sm-4">Portionen</dt>
              <dd className="col-sm-8">
                <input className="form-control" type="number" value={recipe.servings} onChange={event => this.handlePortionsChange(event)} />
                <span className="text-danger field-validation-valid">{(!!this.state.validResult?.servings) ? this.state.validResult.servings : ""}</span>
              </dd>
            </dl>
          </div>
          <div className="col-md-12">
            <div className="jumbotron" style={{ padding: "1rem" }}>
              <EditStepList 
                steps={(!recipe.steps) ? [] : recipe.steps} 
                onStepsChanged={(steps) => this.handleStepsChanged(steps)}
                validResult={(!!this.state.validResult.steps) ? this.state.validResult.steps : {}}
                ingredients={this.state.ingredients} />
            </div>
            <EditSubchapterList 
              subchapters={(!recipe.subchapters) ? [] : recipe.subchapters} 
              onSubchaptersChanged={(subchapters) => this.handleSubchapterChanged(subchapters)} 
              validResult={(!!this.state.validResult.subchapters) ? this.state.validResult.subchapters : {}}
              ingredients={this.state.ingredients}  />
          </div>
          <div className="col-md-12">
            <span className="text-danger field-validation-valid">{(!!this.state.validResult.general) ? <><p/>{this.state.validResult.general}</> : ""}</span>
          </div>
        </div>
        <hr/>
        <input type="submit" value={this.props.saveButton} className="btn btn-primary" onClick={() => this.handleSave()} />

      </>

    )
  }
}

export default EditOrCreate
