import React, { Component } from 'react';
import apiBackend from '../api-backend/ApiBackend';
import EditOrCreate from './EditOrCreate';
import { generatePath } from "react-router";
import { createFullPath } from '../../routing/AppRoutes';
import { withRouter } from '../../routing/withRouter';

class Create extends Component {
  async handleSafeEvent(recipe) {
    var response = await apiBackend.Recipe.create({createRecipeRequest: {recipe : recipe}});
    var forewardPath = generatePath(createFullPath("Recipe","Detail"), {id: response.recipe.id})

    this.props.navigate(forewardPath, {state: {recipe: response.recipe }});
  }
  
  render() { return (<EditOrCreate title="Rezept erstellen" onSaveEvent={(recipe) => this.handleSafeEvent(recipe)} saveButton="Erstellen" />); }
}

export default withRouter(Create)
