import React, { Component } from 'react'
import { generatePath, useNavigate } from "react-router";
import { Link } from 'react-router-dom';
import { Collapse } from 'reactstrap';

import { faChevronDown } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import ApiBackend from '../api-backend/ApiBackend'
import { withRouter } from '../../routing/withRouter';
import { createFullPath } from '../../routing/AppRoutes';


class WeekplanList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded : !!this.props.location.state?.data,
      data : this.props.location.state?.data || {},
      isArchiveOpen: false,
    };
  }


  componentDidMount() {
    if(!this.state.loaded) { this.update(); } // initial update
    this.interval = setInterval(() => this.update(), 100000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async update() {
    var data = (await ApiBackend.Weekplans.listAll());

    this.setState({
      loaded : true,
      data : data,
    });
  }

  toggleArchive() {
    this.setState({isArchiveOpen: !this.state.isArchiveOpen})
  }

  render() {
    return (
      <>
        <h1>Alle Wochenpläne</h1>
        <p><Link to={createFullPath("Weekplan","Create")}>Neue Wochenplan erstellen</Link></p>
        
        { !this.state.loaded ? (<p><em>Lade vorhandene Wochenpläne...</em></p>) : (
          <div className="list-group">{this.state.data.weekplans.map( (weekplan) => (
            <WeekplanListElement key={weekplan.id} weekplan={weekplan} />))}
          </div>
        )}
        { !this.state.loaded || !this.state.data?.archive ? <></> :
            <>
              <h3 style={{"marginTop":"1em"}} onClick={() => this.toggleArchive()}>
                <div className={`${!this.state.isArchiveOpen ? "isRotated" : "isNotRotated"}`} style={{"display":"inline"}}>
                  <FontAwesomeIcon style={{"display":"inline-block"}} icon={faChevronDown}/>
                </div> Archiv
              </h3>
              <Collapse className="list-group" isOpen={this.state.isArchiveOpen}>
              {this.state.data.archive.map( (weekplan) => (
                <WeekplanListElement key={weekplan.id} weekplan={weekplan} />))}
              </Collapse>
            </>
        }

      </>)
  }
}

export default withRouter(WeekplanList);


class WeekplanListElement extends Component {
  render() {
    const {weekplan} = this.props;
    const yearStart = weekplan.start.getFullYear();
    const yearEnd = weekplan.end.getFullYear();
    const montStart = weekplan.start.getMonth();
    const montEnd = weekplan.end.getMonth();

    const lenthFirstDate = yearStart !== yearEnd ? 10 : (montStart !== montEnd ? 6 : 3);
    const title = this.formatDate(weekplan.start).substring(0,lenthFirstDate) + " - " + this.formatDate(weekplan.end);
    const path = generatePath(createFullPath("Weekplan", "Detail"), {id: this.props.weekplan.id});

    return (
      <LinkNavigate className="list-group-item list-group-item-action" to={path}>{title}</LinkNavigate>
    )
  }

  formatDate(inputDate) {
    let date, month, year;
  
    date = inputDate.getDate();
    month = inputDate.getMonth() + 1;
    year = inputDate.getFullYear();
  
      date = date
          .toString()
          .padStart(2, '0');
  
      month = month
          .toString()
          .padStart(2, '0');
  
    return `${date}.${month}.${year}`;
  }

}

function LinkNavigate(props) {
  const style = !props.isVariant ? {cursor:"pointer"} : {paddingLeft: "2em", cursor:"pointer"};
  let navigate = useNavigate();

  function openDetails(eventArgs) {
    // avoid opening the details in case of clicking on links
    if (eventArgs.target.nodeName === "DIV") {
      // create the path and navigate foreward
      navigate(props.to);
    }
  }
  return (
    <div className={props.className} onClick={openDetails} style={style}>
      {props.children}
    </div>
  )
}
