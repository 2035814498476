import React from 'react'
import { Navigate } from 'react-router-dom';

// Account

// Recipes
import RecipeList from '../components/Recipe/RecipeList';
import RecipeCreate from '../components/Recipe/Create';
import RecipeCreateVariant from '../components/Recipe/CreateVariant';
import RecipeDetail from '../components/Recipe/Detail';
import RecipeEdit from '../components/Recipe/Edit';

// Ingredients
import IngredientList from '../components/Ingredient/IngredientList';
import IngredientDetail from '../components/Ingredient/Detail';
import IngredientCreate from '../components/Ingredient/Create';
import IngredientEdit from '../components/Ingredient/Edit';

// Authentication
import Login from '../components/authentication/Login';
import Logout from '../components/authentication/Logout';
import Register from '../components/authentication/Register';



import Imprint from '../components/Imprint';
import DataProtection from '../components/DataProtection';
import WeekplanList from '../components/Weekplan/WeekplanList';
import WeekplanCreate from '../components/Weekplan/Create';
import WeekplanDetail from '../components/Weekplan/Detail';


// predefined routes (used twice in the AppRoutes)
const AppRoutes_Recipes_outeProps_path = 'Recipes';


const AppRoutes = {
  Home: { element: <Navigate to={AppRoutes_Recipes_outeProps_path} />, routeProps: { index: true } },
  
  Recipes: { element: <RecipeList />, routeProps: { path: AppRoutes_Recipes_outeProps_path } },
  Recipe: {
    isIndex: true, routeProps: { path: 'Recipe' },
    subRoutes: {
      Detail: { element: <RecipeDetail />, routeProps: { path: ':id' } },
      Edit: { element: <RecipeEdit />, routeProps: { path: ':id/Edit' } },
      Create: { element: <RecipeCreate />, routeProps: { path: 'Create' } },
      CreateVariant: { element: <RecipeCreateVariant />, routeProps: { path: ':id/CreateVariant' } },
    }
  },

  Ingredients : { element: <IngredientList />, routeProps: { path: "Ingredients" } },
  Ingredient : {
    isIndex: true, routeProps: { path: 'Ingredient' },
    subRoutes: {
      Detail: { element: <IngredientDetail />, routeProps: { path: ':id' } },
      Edit: { element: <IngredientEdit />, routeProps: { path: ':id/Edit' } },
      Create: { element: <IngredientCreate />, routeProps: { path: 'Create' } },
    }
  },

  Weekplans : { 
    element: <WeekplanList />, routeProps: { path: "Weekplans", isAuthOnly: true } 
  },
  Weekplan : {
    isIndex: true, routeProps: { path: 'Weekplan' },
    subRoutes: {
      Detail: { element: <WeekplanDetail/>, routeProps: { path: ':id' } },
      Create: { element: <WeekplanCreate/>, routeProps: { path: 'Create' } },
    }
  },

  Authentication : {
    isIndex: true, routeProps: { path: 'Authentication' },
    subRoutes : {
      Login: { element: <Login/>, routeProps: { path: 'Login' } }, 
      Register: { element: <Register/>, routeProps: { path: 'Register' } }, 
      LogOut: { element: <Logout/>, routeProps: { path: 'Logout' } }, 
    }
  },

  Account : {
    isIndex: true, routeProps: { path: 'Account' },
    subRoutes : {
      Profile: { element: <h1>Profile</h1>, routeProps: { path: 'Profile' } }, 
      ForgotPassword: { element: <h1>ForgotPassword</h1>, routeProps: { path: 'ForgotPassword' } }, 
    }
  },

  Imprint: { element: <Imprint />, routeProps: { path: 'Impressum' } },
  DataProtection: { element: <DataProtection />, routeProps: { path: 'Datenschutz' } },
};

export function createFullPath(key, subkey) {
  return "/" + AppRoutes[key].routeProps.path + "/" + AppRoutes[key].subRoutes[subkey].routeProps.path;
} 

export default AppRoutes;
