/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateIngredientRequest,
    CreateIngredientRequestFromJSON,
    CreateIngredientRequestToJSON,
    IngredientDeletedResponse,
    IngredientDeletedResponseFromJSON,
    IngredientDeletedResponseToJSON,
    IngredientDetailedResponse,
    IngredientDetailedResponseFromJSON,
    IngredientDetailedResponseToJSON,
    UpdateIngredientRequest,
    UpdateIngredientRequestFromJSON,
    UpdateIngredientRequestToJSON,
} from '../models';

export interface IngredientApiCreateRequest {
    createIngredientRequest?: CreateIngredientRequest;
}

export interface IngredientApiIdRequest {
    id: number;
}

export interface IngredientApiIdDeleteRequest {
    id: number;
}

export interface IngredientApiIdUpdateRequest {
    id: number;
    updateIngredientRequest?: UpdateIngredientRequest;
}

/**
 * 
 */
export class IngredientApi extends runtime.BaseAPI {

    /**
     */
    async createRaw(requestParameters: IngredientApiCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IngredientDetailedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Ingredient/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateIngredientRequestToJSON(requestParameters.createIngredientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngredientDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: IngredientApiCreateRequest, initOverrides?: RequestInit): Promise<IngredientDetailedResponse> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idRaw(requestParameters: IngredientApiIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IngredientDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling id.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Ingredient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngredientDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async id(requestParameters: IngredientApiIdRequest, initOverrides?: RequestInit): Promise<IngredientDetailedResponse> {
        const response = await this.idRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idDeleteRaw(requestParameters: IngredientApiIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IngredientDeletedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Ingredient/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngredientDeletedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idDelete(requestParameters: IngredientApiIdDeleteRequest, initOverrides?: RequestInit): Promise<IngredientDeletedResponse> {
        const response = await this.idDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idUpdateRaw(requestParameters: IngredientApiIdUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<IngredientDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Ingredient/{id}/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateIngredientRequestToJSON(requestParameters.updateIngredientRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => IngredientDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idUpdate(requestParameters: IngredientApiIdUpdateRequest, initOverrides?: RequestInit): Promise<IngredientDetailedResponse> {
        const response = await this.idUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
