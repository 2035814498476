import React, { Component } from 'react';
import { generatePath } from 'react-router-dom';
import { ModalManager } from 'react-dynamic-modal';
import { createFullPath } from '../../routing/AppRoutes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faChevronDown, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Collapse } from 'reactstrap';
import AddMealModal from './AddMealModal';
import ApiBackend from '../api-backend/ApiBackend';

export class Meals extends Component {
  constructor(props) {
    super(props);

    // take the state either from the location state or set it to not loaded
    this.state = {};

    const isEditable = !!this.props.onWeekplanChanged;

    Object.keys(this.props.weekplan.mealTypes).forEach(elm => 
      this.state[elm + "IsOpen"] = !isEditable || 
        this.props.weekplan.mealTypes[elm].planedNumberOfMeals > this.props.weekplan.mealTypes[elm].meals.length);
  }

  toggleIsOpen(elm) {
    this.setState({ [elm + "IsOpen"]: !this.state[elm + "IsOpen"] });
  }

  openAddMealModal(mealType) {
    ModalManager.open(<AddMealModal
      weekplan={this.props.weekplan}
      onRequestClose={() => true}
      onMealAdded={(weekplan) => this.handleMealAdded(mealType, weekplan)}
      mealType={mealType} />);
  }

  handleMealAdded(mealType, weekplan) {
    const mealTypeValues = weekplan.mealTypes[mealType]
    if(mealTypeValues.planedNumberOfMeals <= mealTypeValues.meals.length) this.setState({[mealType + "IsOpen"]: false})
    this.props.onWeekplanChanged(weekplan)
  }

  async deleteMeal(mealId) {
    var response = await ApiBackend.Weekplan.idRemoveMealmealId({
      id: this.props.weekplan.id,
      mealId: mealId})

    this.props.onWeekplanChanged(response.weekplan)
  }


  renderMealTypeMeal(meal) {
    const isNotEditable = !this.props.onWeekplanChanged;

    return (<div className="col-12 row" key={meal.id}>
      <div className="col-6 col-lg-4" style={{"marginTop": "9px"}} >
        {meal.recipe.name} 
        {isNotEditable ? <></> : <FontAwesomeIcon className="float-right" onClick={() => this.deleteMeal(meal.id)} icon={faTrashAlt} /> }
      </div>
      <div className="col-6 col-lg-5" style={{ "marginBottom": "10px" }}>
        <div className="input-group">
          <div className="input-group-prepend" style={{ "minWidth": "50%" }}>
            <span className="input-group-text" style={{ "minWidth": "100%" }}>
              <a href={generatePath(createFullPath("Recipe", "Detail"), { id: meal.recipe.id })} target="_blank" rel="noreferrer" tabIndex="-1">Basisvariante</a>
            </span>
          </div>
          <input className="form-control" value={meal.servings} style={{ "maxWidth": "50%" }} readOnly />
        </div>

        {meal.variants.map((variant) => {

          return (
            <div className="input-group" key={variant.recipe.id}>
              <div className="input-group-prepend" style={{ "minWidth": "50%" }}>
                <span className="input-group-text" style={{ "minWidth": "100%" }}>
                  <a href={generatePath(createFullPath("Recipe", "Detail"), { id: variant.recipe.id })} target="_blank" rel="noreferrer" tabIndex="-1">{variant.recipe.name}</a>
                </span>
              </div>
              <input className="form-control" value={variant.servings} style={{ "maxWidth": "50%" }} readOnly />
            </div>
          );
        })}
      </div>
      <br />
    </div>);
  }

  renderMealType(elm, data) {
    const { weekplan } = this.props;
    const isEditable = !!this.props.onWeekplanChanged;
    const mealType = weekplan.mealTypes[elm];
    const mealsInType = mealType.meals;

    const buttonClass = mealsInType.length >= mealType.planedNumberOfMeals ? "btn btn-secondary" : "btn btn-success"

    return (<div key={elm}>
      <h6 style={{ "marginTop": "1em", "marginLeft": "-18px", "fontWeight": 700 }} onClick={() => this.toggleIsOpen(elm)}>
        <div className={`${!this.state[elm + "IsOpen"] ? "isRotated" : "isNotRotated"}`} style={{ "display": "inline" }}>
          <FontAwesomeIcon style={{ "display": "inline-block" }} icon={faChevronDown} />
        </div> {data.name} ({mealsInType.length}/{mealType.planedNumberOfMeals})
      </h6>
      <Collapse className="list-group" isOpen={this.state[elm + "IsOpen"]} style={{ "marginLeft": "1em" }}>
        <div className="row">{mealsInType.map((meal) => this.renderMealTypeMeal(meal))}
          {!isEditable ? <></> : <div className="col-12 row"><div className="col-12 col-lg-9"><button  type="button" className={buttonClass}
            onClick={() => this.openAddMealModal(elm)} style={{"width": "100%"}}><FontAwesomeIcon icon={faAdd} /></button></div></div>}
        </div>
      </Collapse>
    </div>);
  }


  render() {
    const types = this.props.weekplan.mealTypes;

    return (
      <>
        <h4>Geplante Essen</h4>
        {Object.keys(types).map(elm => this.renderMealType(elm, types[elm]))}
      </>
    );

  }
}
