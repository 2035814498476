import React,{Component} from 'react';
import { Modal,ModalManager,Effect} from 'react-dynamic-modal';
import apiBackend from '../api-backend/ApiBackend';

import { generatePath } from "react-router";
import { createFullPath } from '../../routing/AppRoutes';

 
class DeleteModal extends Component{
   constructor(props) {
      super(props);
  
      this.state = {
        success : undefined,
        usedIn : []
      };
    }

   async onDelete(id) {
      var response = await apiBackend.Ingredient.idDelete({id: id})

      if (response.success) {
         this.props.onStateUpdate(response.ingredients)
         ModalManager.close();
      }
      else
      {
         this.setState(response)
      }
   }


   render(){
      const { ingredient, onRequestClose } = this.props;
 
      var result = undefined;

      if(this.state.success !== false) {
         result = <>           
            <div className="modal-header">
               <h4 className="modal-title">Soll die Zutat {ingredient.name} gelöscht werden?</h4>
            </div>
            <div className="modal-footer">
               <button type="button" className="btn btn-danger" onClick={() => this.onDelete(ingredient.id)}>Löschen</button>
               <button type="button" className="btn btn-default" onClick={ModalManager.close}>Schließen</button>
            </div>
         </>
      } else {
         result = <>           
            <div className="modal-header">
               <h4 className="modal-title">Die Zutat {ingredient.name} kann nicht gelöscht werden.</h4>
            </div>
            <div className="modal-body">
               Die Zutat {ingredient.name} wird aktuell in folgenden Elementen verwendet und kann deshalb nicht gelöscht werden:
               {this.state.usedIn.map(elm => <UsiedInElement key={elm.id} elm={elm}/>)}
            </div>
            <div className="modal-footer">
               <button type="button" className="btn btn-primary" onClick={ModalManager.close}>Schließen</button>
            </div>
         </>

      }

      return (
         <Modal onRequestClose={onRequestClose} effect={Effect.ScaleUp}>
            {result}
         </Modal>
      );
   }
}

export default DeleteModal

class UsiedInElement extends Component {

   render() {
      const {elm} = this.props; 

      var type = "Unbekannt";
      var target = "#";
      switch(elm.type) {
         case "Recipe":
           type = "Rezept";
           target = generatePath(createFullPath("Recipe","Detail"), {id: elm.id});
           break;
         case "Weekplan":
           type = "Wochenplan";
           target = generatePath(createFullPath("Weekplan","Detail"), {id: elm.id});
           break;
         default:
           break;
       }

      return(
         <li>
            <a href={target} target="_blank" rel="noopener noreferrer" >
               {type}: {elm.name}
            </a>
         </li>
      )
   }
}
