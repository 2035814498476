import { Configuration } from "./runtime";
import authServics from "../authorization/AuthorizeService";
import { backendConfig } from "./config";
import * as Apis from "./apis";

export class ApiBackendClass {
  // CODEGEN <Declaring>
  public Accounts : Apis.AccountsApi;
  public Auth : Apis.AuthApi;
  public Ingredient : Apis.IngredientApi;
  public Ingredients : Apis.IngredientsApi;
  public Profile : Apis.ProfileApi;
  public Recipe : Apis.RecipeApi;
  public Recipes : Apis.RecipesApi;
  public Roles : Apis.RolesApi;
  public Version : Apis.VersionApi;
  public Weekplan : Apis.WeekplanApi;
  public Weekplans : Apis.WeekplansApi;
  // CODEGEN </Declaring>

  constructor() {
    let configuration = new Configuration({ ...backendConfig,
      "accessToken" : authServics.getAccessToken,
    });

    // CODEGEN <Instantiating>
    this.Accounts = new Apis.AccountsApi(configuration);
    this.Auth = new Apis.AuthApi(configuration);
    this.Ingredient = new Apis.IngredientApi(configuration);
    this.Ingredients = new Apis.IngredientsApi(configuration);
    this.Profile = new Apis.ProfileApi(configuration);
    this.Recipe = new Apis.RecipeApi(configuration);
    this.Recipes = new Apis.RecipesApi(configuration);
    this.Roles = new Apis.RolesApi(configuration);
    this.Version = new Apis.VersionApi(configuration);
    this.Weekplan = new Apis.WeekplanApi(configuration);
    this.Weekplans = new Apis.WeekplansApi(configuration);
    // CODEGEN </Instantiating>
  } // constructor

} // export class ApiBackendClass


const ApiBackend = new ApiBackendClass();
export default ApiBackend;