/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeStepDetailed,
    RecipeStepDetailedFromJSON,
    RecipeStepDetailedFromJSONTyped,
    RecipeStepDetailedToJSON,
    RecipeSubchapterDetailed,
    RecipeSubchapterDetailedFromJSON,
    RecipeSubchapterDetailedFromJSONTyped,
    RecipeSubchapterDetailedToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeSummary
 */
export interface RecipeSummary {
    /**
     * 
     * @type {number}
     * @memberof RecipeSummary
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecipeSummary
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RecipeSummary
     */
    servings: number;
    /**
     * 
     * @type {Array<RecipeStepDetailed>}
     * @memberof RecipeSummary
     */
    steps?: Array<RecipeStepDetailed> | null;
    /**
     * 
     * @type {Array<RecipeSubchapterDetailed>}
     * @memberof RecipeSummary
     */
    subchapters?: Array<RecipeSubchapterDetailed> | null;
}

export function RecipeSummaryFromJSON(json: any): RecipeSummary {
    return RecipeSummaryFromJSONTyped(json, false);
}

export function RecipeSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'servings': json['servings'],
        'steps': !exists(json, 'steps') ? undefined : (json['steps'] === null ? null : (json['steps'] as Array<any>).map(RecipeStepDetailedFromJSON)),
        'subchapters': !exists(json, 'subchapters') ? undefined : (json['subchapters'] === null ? null : (json['subchapters'] as Array<any>).map(RecipeSubchapterDetailedFromJSON)),
    };
}

export function RecipeSummaryToJSON(value?: RecipeSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'servings': value.servings,
        'steps': value.steps === undefined ? undefined : (value.steps === null ? null : (value.steps as Array<any>).map(RecipeStepDetailedToJSON)),
        'subchapters': value.subchapters === undefined ? undefined : (value.subchapters === null ? null : (value.subchapters as Array<any>).map(RecipeSubchapterDetailedToJSON)),
    };
}


