/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface VariantServings
 */
export interface VariantServings {
    /**
     * 
     * @type {number}
     * @memberof VariantServings
     */
    variantId?: number;
    /**
     * 
     * @type {number}
     * @memberof VariantServings
     */
    servings?: number;
}

export function VariantServingsFromJSON(json: any): VariantServings {
    return VariantServingsFromJSONTyped(json, false);
}

export function VariantServingsFromJSONTyped(json: any, ignoreDiscriminator: boolean): VariantServings {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'variantId': !exists(json, 'variantId') ? undefined : json['variantId'],
        'servings': !exists(json, 'servings') ? undefined : json['servings'],
    };
}

export function VariantServingsToJSON(value?: VariantServings | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'variantId': value.variantId,
        'servings': value.servings,
    };
}


