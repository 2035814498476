/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IngredientSummary,
    IngredientSummaryFromJSON,
    IngredientSummaryFromJSONTyped,
    IngredientSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface IngredientSummaryListResponse
 */
export interface IngredientSummaryListResponse {
    /**
     * 
     * @type {Array<IngredientSummary>}
     * @memberof IngredientSummaryListResponse
     */
    ingredients?: Array<IngredientSummary> | null;
}

export function IngredientSummaryListResponseFromJSON(json: any): IngredientSummaryListResponse {
    return IngredientSummaryListResponseFromJSONTyped(json, false);
}

export function IngredientSummaryListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngredientSummaryListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'ingredients': !exists(json, 'ingredients') ? undefined : (json['ingredients'] === null ? null : (json['ingredients'] as Array<any>).map(IngredientSummaryFromJSON)),
    };
}

export function IngredientSummaryListResponseToJSON(value?: IngredientSummaryListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'ingredients': value.ingredients === undefined ? undefined : (value.ingredients === null ? null : (value.ingredients as Array<any>).map(IngredientSummaryToJSON)),
    };
}


