/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeMeta,
    RecipeMetaFromJSON,
    RecipeMetaFromJSONTyped,
    RecipeMetaToJSON,
    RecipeStepDetailed,
    RecipeStepDetailedFromJSON,
    RecipeStepDetailedFromJSONTyped,
    RecipeStepDetailedToJSON,
    RecipeSubchapterDetailed,
    RecipeSubchapterDetailedFromJSON,
    RecipeSubchapterDetailedFromJSONTyped,
    RecipeSubchapterDetailedToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeDetailed
 */
export interface RecipeDetailed {
    /**
     * 
     * @type {number}
     * @memberof RecipeDetailed
     */
    id?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecipeDetailed
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof RecipeDetailed
     */
    servings: number;
    /**
     * 
     * @type {Array<RecipeStepDetailed>}
     * @memberof RecipeDetailed
     */
    steps?: Array<RecipeStepDetailed> | null;
    /**
     * 
     * @type {Array<RecipeSubchapterDetailed>}
     * @memberof RecipeDetailed
     */
    subchapters?: Array<RecipeSubchapterDetailed> | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecipeDetailed
     */
    isVariant?: boolean;
    /**
     * 
     * @type {number}
     * @memberof RecipeDetailed
     */
    baseRecipeId?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RecipeDetailed
     */
    baseRecipeName?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof RecipeDetailed
     */
    hasVariants?: boolean;
    /**
     * 
     * @type {Array<RecipeMeta>}
     * @memberof RecipeDetailed
     */
    variants?: Array<RecipeMeta> | null;
}

export function RecipeDetailedFromJSON(json: any): RecipeDetailed {
    return RecipeDetailedFromJSONTyped(json, false);
}

export function RecipeDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': json['name'],
        'servings': json['servings'],
        'steps': !exists(json, 'steps') ? undefined : (json['steps'] === null ? null : (json['steps'] as Array<any>).map(RecipeStepDetailedFromJSON)),
        'subchapters': !exists(json, 'subchapters') ? undefined : (json['subchapters'] === null ? null : (json['subchapters'] as Array<any>).map(RecipeSubchapterDetailedFromJSON)),
        'isVariant': !exists(json, 'isVariant') ? undefined : json['isVariant'],
        'baseRecipeId': !exists(json, 'baseRecipeId') ? undefined : json['baseRecipeId'],
        'baseRecipeName': !exists(json, 'baseRecipeName') ? undefined : json['baseRecipeName'],
        'hasVariants': !exists(json, 'hasVariants') ? undefined : json['hasVariants'],
        'variants': !exists(json, 'variants') ? undefined : (json['variants'] === null ? null : (json['variants'] as Array<any>).map(RecipeMetaFromJSON)),
    };
}

export function RecipeDetailedToJSON(value?: RecipeDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'servings': value.servings,
        'steps': value.steps === undefined ? undefined : (value.steps === null ? null : (value.steps as Array<any>).map(RecipeStepDetailedToJSON)),
        'subchapters': value.subchapters === undefined ? undefined : (value.subchapters === null ? null : (value.subchapters as Array<any>).map(RecipeSubchapterDetailedToJSON)),
        'isVariant': value.isVariant,
        'baseRecipeId': value.baseRecipeId,
        'baseRecipeName': value.baseRecipeName,
        'hasVariants': value.hasVariants,
        'variants': value.variants === undefined ? undefined : (value.variants === null ? null : (value.variants as Array<any>).map(RecipeMetaToJSON)),
    };
}


