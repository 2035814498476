import React, { Component } from 'react';
import apiBackend from '../api-backend/ApiBackend';
import EditOrCreate from './EditOrCreate';
import { generatePath } from "react-router";
import { withRouter } from '../../routing/withRouter';
import { createFullPath } from '../../routing/AppRoutes';

class Edit extends Component {
  constructor(props) {
    super(props);

    // take the state either from the location state or set it to not loaded
    this.state = {
      loaded : props.location.state && (!!props.location.state.ingredient),
      ingredient : props.location.state && (props.location.state.ingredient || {}),
    };
  }
    
    
  componentDidMount() {
    if(!this.state.loaded) this.update(); // initial update
  }


  async update() {
    const ingredient = (await apiBackend.Ingredient.id({id: this.props.params.id})).ingredient;
  
    this.setState(state => ({
      loaded : true,
      ingredient : ingredient,
    }));
  }
  

  async handleSafeEvent(ingredient) {
    var response = await apiBackend.Ingredient.idUpdate({id: ingredient.id, updateIngredientRequest: {ingredient : ingredient}});
    var forewardPath = generatePath(createFullPath("Ingredient","Detail"), {id: response.ingredient.id})

    this.props.navigate( forewardPath, {state: { ingredient: response.ingredient }})
  }
  
  render() {
    return (
      <>{ !this.state.loaded ? (
        <p><em>Lade Zutat...</em></p>) : 
        <EditOrCreate title="Zutat bearbeiten" ingredient={this.state.ingredient} onSaveEvent={(ingredient) => this.handleSafeEvent(ingredient)} saveButton="Speichern" />}
      </>
    )
  }
}

export default withRouter(Edit)
