import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { generatePath } from "react-router";

import ApiBackend from '../api-backend/ApiBackend'
import DeleteModal from './DeleteModal';
import { ModalManager } from 'react-dynamic-modal';
import { withRouter } from '../../routing/withRouter';
import AppRoutes, { createFullPath } from '../../routing/AppRoutes';


class Detail extends Component {
  constructor(props) {
    super(props);

    // take the state either from the location state or set it to not loaded
    this.state = {
      loaded : !!props.location.state?.ingredient,
      ingredient : props.location.state?.ingredient || {},
    };
  }
    
    
  componentDidMount() {
    if(!this.state.loaded) this.update(); // initial update
  }


  async update() {
    const ingredient = (await ApiBackend.Ingredient.id({id: this.props.params.id})).ingredient;
  
    this.setState(state => ({
      loaded : true,
      ingredient : ingredient,
    }));
  }
  
  
  openDeleteModal(ingredient) {
    ModalManager.open(<DeleteModal ingredient={ingredient} onStateUpdate={state => this.OnDeleteFinished(state)} onRequestClose={() => true}/>);
  }
  OnDeleteFinished(state) {
    this.props.navigate(AppRoutes.Ingredients.routeProps.path, {state: { ingredients: state }})
  }
  
  render() {
    const { ingredient } = this.state;

    return (
      <>{ !this.state.loaded ? (<p><em>Lade Zutat...</em></p>) : (
        <>
          <h1>Zutat - {ingredient.name}</h1>

          <div>
            <hr />
            <dl className="row">
              <dt className="col-sm-2">Name</dt>
              <dd className="col-sm-10">{ingredient.name}</dd>
            </dl>
          </div>
      
          <PortionList portions={ingredient.portions} />
          <div>
            <Link to={{
              pathname: generatePath(createFullPath("Ingredient","Edit"), {id: ingredient.id}),
              state: { ingredient: ingredient }
            }}>Bearbeiten</Link> |&nbsp;
            <Link to="#" onClick={() => this.openDeleteModal(ingredient)}>Löschen</Link> |&nbsp; 
            <Link to={"/" + AppRoutes.Ingredients.routeProps.path}>Zurück zur Liste</Link>
          </div>

        </>)}
      </>
    )
  }
}

export default withRouter(Detail)

class PortionList extends Component {

  render() {
    const {portions} = this.props;
    
    return(
      <div className="col-6">
        <table className="table table-striped">
          <tbody>
            <tr>
              <th>Verfügbare Portionen</th>
              <th>Portionsgewicht</th>
            </tr>

            {portions.map((portion) => 
              {
                const style = portion.isDefault ? {fontWeight: 700, textDecoration: "underline"} : {};
                return (
                  <tr key={portion.id}>
                    <td style={style}>{portion.name}</td>
                    <td>{portion.isApproximal? "~" : ""}{portion.weight}g</td>
                  </tr>
                )
              }
            )}
          </tbody>
        </table>
      </div>
    )
  }
}
