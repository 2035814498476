import React, { Component } from 'react';
import ApiBackend from '../api-backend/ApiBackend';
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditOrCreate from './EditOrCreate';

export class StartEnd extends Component {

  date2string(date) {
    // Get year, month, and day part from the date
    const year = date.toLocaleString("default", { year: "numeric" });
    const month = date.toLocaleString("default", { month: "2-digit" });
    const day = date.toLocaleString("default", { day: "2-digit" });

    // Generate yyyy-mm-dd date string
    var formattedDate = day + "." + month + "." + year;
    return formattedDate;
  }

  goToEditMode(elm) {
    this.setState({ editMode: elm });
  }
  cancelEditMode(elm) {
    if (this.state.editMode === elm)
      this.setState({ editMode: "" });
  }

  state = {
    editMode: "",
  };

  async handleSafeEvent(weekplan, elm) {
    const value = { id: this.props.weekplan.id, updateWeekplanRequest: weekplan };
    var response = await ApiBackend.Weekplan.idUpdate(value);

    if (this.state.editMode === elm)
      this.setState({ editMode: "" });

    if (!!this.props.onWeekplanChanged)
      this.props.onWeekplanChanged(response.weekplan);
  }



  render() {
    const { weekplan } = this.props;
    const isEditable = !!this.props.onWeekplanChanged;

    var startElms = <></>;
    if (this.state.editMode === "start") {
      startElms = <EditOrCreate
        saveButton="Speichern"
        weekplan={weekplan}
        onSaveEvent={(weekplan) => this.handleSafeEvent(weekplan, "start")}
        onCancelEvent={() => this.cancelEditMode("start")}
        type="startOnly" />;
    } else {
      startElms = <>
        {this.date2string(weekplan.start)}
        {isEditable ? <> <FontAwesomeIcon style={{ "marginLeft": "1em" }} icon={faEdit} onClick={() => this.goToEditMode("start")} /></> : <></>}
      </>;
    }
    var endElms = <></>;
    if (this.state.editMode === "end") {
      endElms = <EditOrCreate
        saveButton="Speichern"
        weekplan={weekplan}
        onSaveEvent={(weekplan) => this.handleSafeEvent(weekplan, "end")}
        onCancelEvent={() => this.cancelEditMode("end")}
        type="endOnly" />;
    } else {
      endElms = <>
        {this.date2string(weekplan.end)}
        {isEditable ? <> <FontAwesomeIcon style={{ "marginLeft": "1em" }} icon={faEdit} onClick={() => this.goToEditMode("end")} /></> : <></>}
      </>;
    }


    return (
      <>
        <dt className="col-sm-2">Start</dt>
        <dd className="col-sm-10">{startElms}</dd>
        <dt className="col-sm-2">Ende</dt>
        <dd className="col-sm-10">{endElms}</dd>
      </>);
  }
}
