/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IngredientAmountSummary,
    IngredientAmountSummaryFromJSON,
    IngredientAmountSummaryFromJSONTyped,
    IngredientAmountSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeStepDetailed
 */
export interface RecipeStepDetailed {
    /**
     * 
     * @type {number}
     * @memberof RecipeStepDetailed
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RecipeStepDetailed
     */
    order?: number;
    /**
     * 
     * @type {string}
     * @memberof RecipeStepDetailed
     */
    text?: string | null;
    /**
     * 
     * @type {Array<IngredientAmountSummary>}
     * @memberof RecipeStepDetailed
     */
    ingredients?: Array<IngredientAmountSummary> | null;
}

export function RecipeStepDetailedFromJSON(json: any): RecipeStepDetailed {
    return RecipeStepDetailedFromJSONTyped(json, false);
}

export function RecipeStepDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeStepDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'order': !exists(json, 'order') ? undefined : json['order'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'ingredients': !exists(json, 'ingredients') ? undefined : (json['ingredients'] === null ? null : (json['ingredients'] as Array<any>).map(IngredientAmountSummaryFromJSON)),
    };
}

export function RecipeStepDetailedToJSON(value?: RecipeStepDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'order': value.order,
        'text': value.text,
        'ingredients': value.ingredients === undefined ? undefined : (value.ingredients === null ? null : (value.ingredients as Array<any>).map(IngredientAmountSummaryToJSON)),
    };
}


