/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface IngredientAmountSummary
 */
export interface IngredientAmountSummary {
    /**
     * 
     * @type {number}
     * @memberof IngredientAmountSummary
     */
    id?: number | null;
    /**
     * 
     * @type {number}
     * @memberof IngredientAmountSummary
     */
    amount?: number;
    /**
     * 
     * @type {string}
     * @memberof IngredientAmountSummary
     */
    portionName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IngredientAmountSummary
     */
    ingredientName?: string | null;
    /**
     * 
     * @type {number}
     * @memberof IngredientAmountSummary
     */
    portionId: number;
    /**
     * 
     * @type {number}
     * @memberof IngredientAmountSummary
     */
    ingredientId: number;
}

export function IngredientAmountSummaryFromJSON(json: any): IngredientAmountSummary {
    return IngredientAmountSummaryFromJSONTyped(json, false);
}

export function IngredientAmountSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngredientAmountSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'portionName': !exists(json, 'portionName') ? undefined : json['portionName'],
        'ingredientName': !exists(json, 'ingredientName') ? undefined : json['ingredientName'],
        'portionId': json['portionId'],
        'ingredientId': json['ingredientId'],
    };
}

export function IngredientAmountSummaryToJSON(value?: IngredientAmountSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'amount': value.amount,
        'portionName': value.portionName,
        'ingredientName': value.ingredientName,
        'portionId': value.portionId,
        'ingredientId': value.ingredientId,
    };
}


