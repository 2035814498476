import React, { Component } from 'react'
import { generatePath, useNavigate } from "react-router";
import { Link } from 'react-router-dom';

import ApiBackend from '../api-backend/ApiBackend'
import { withRouter } from '../../routing/withRouter';
import { createFullPath } from '../../routing/AppRoutes';

class RecipeList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      loaded : !!this.props.location.state?.recipes,
      recipes : this.props.location.state?.recipes || {},
    };
  }


  componentDidMount() {
    if(!this.state.loaded) { this.update(); } // initial update
    this.interval = setInterval(() => this.update(), 100000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }
  async update(recipes) {
    if(recipes == null) {
      recipes = (await ApiBackend.Recipes.listAll()).recipes;
    } 

    this.setState(state => ({
      loaded : true,
      recipes : recipes,
    }));
  }


  render() {
    return (
      <>
        <h1>Alle Rezepte</h1>
        <p><Link to={createFullPath("Recipe","Create")}>Neues Rezept erstellen</Link></p>
        
        { !this.state.loaded ? (<p><em>Lade vorhandene Rezepte...</em></p>) : (
          <div className="list-group">{this.state.recipes.map( (recipe) => (
            <RecipeListElement key={recipe.id} recipe={recipe} />))}
          </div>
        )}
      </>)
  }
}

export default withRouter(RecipeList);


class RecipeListElement extends Component {
  render() {
    return (
      <RecipeElement recipe={this.props.recipe} >

        {!this.props.recipe.hasVariants ? <></> :
          <table className="table" style={{width:"100%", marginTop: "1em"}}>
            <tbody>
              { this.props.recipe.variants.map( (variant) => (
                <RecipeVariantElement key={variant.id} isVariant={true} recipe={variant} />
              ))}
            </tbody>
          </table>
        }
      </RecipeElement>
    )
  }
}


function RecipeElement(props) {
  const style = !props.isVariant ? {cursor:"pointer"} : {paddingLeft: "2em", cursor:"pointer"};
  let navigate = useNavigate();

  function openDetails(eventArgs) {
    // avoid opening the details in case of clicking on links
    if (eventArgs.target.nodeName === "DIV") {
      // create the path and navigate foreward
      navigate(generatePath(createFullPath("Recipe", "Detail"), {id: props.recipe.id}));
    }
  }
  return (
    <div className="list-group-item list-group-item-action" onClick={openDetails} style={style}>
      {props.recipe.name}

      <div className="float-right">
        <Link to={ generatePath(createFullPath("Recipe", "Edit"), {id: props.recipe.id}) }>Bearbeiten</Link>
      </div>
      {props.children}
    </div>
  )
}
function RecipeVariantElement(props) {
  const style = !props.isVariant ? {cursor:"pointer"} : {paddingLeft: "2em", cursor:"pointer"};
  let navigate = useNavigate();

  function openDetails(eventArgs) {
    // avoid opening the details in case of clicking on links
    if (eventArgs.target.nodeName === "TD") {
      // create the path and navigate foreward
      navigate(generatePath(createFullPath("Recipe", "Detail"), {id: props.recipe.id}));
    }
  }
  return (
    <tr onClick={openDetails} style={style}>
      <td style={{paddingRight:"0px"}}>
        {props.recipe.name}

        <div className="float-right">
          <Link to={ generatePath(createFullPath("Recipe", "Edit"), {id: props.recipe.id}) }>Bearbeiten</Link>
        </div>
      </td>
    </tr>

  )
}
