/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface AddBringListRequest
 */
export interface AddBringListRequest {
    /**
     * 
     * @type {string}
     * @memberof AddBringListRequest
     */
    userName?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddBringListRequest
     */
    password?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddBringListRequest
     */
    listId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddBringListRequest
     */
    name?: string | null;
}

export function AddBringListRequestFromJSON(json: any): AddBringListRequest {
    return AddBringListRequestFromJSONTyped(json, false);
}

export function AddBringListRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): AddBringListRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'userName': !exists(json, 'userName') ? undefined : json['userName'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'listId': !exists(json, 'listId') ? undefined : json['listId'],
        'name': !exists(json, 'name') ? undefined : json['name'],
    };
}

export function AddBringListRequestToJSON(value?: AddBringListRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'userName': value.userName,
        'password': value.password,
        'listId': value.listId,
        'name': value.name,
    };
}


