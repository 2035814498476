/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangedPlanedMealsInWeekplanRequest
 */
export interface ChangedPlanedMealsInWeekplanRequest {
    /**
     * 
     * @type {number}
     * @memberof ChangedPlanedMealsInWeekplanRequest
     */
    planedNumberOfBreakfast?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChangedPlanedMealsInWeekplanRequest
     */
    planedNumberOfLunch?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChangedPlanedMealsInWeekplanRequest
     */
    planedNumberOfAfternoonSnack?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ChangedPlanedMealsInWeekplanRequest
     */
    planedNumberOfDinner?: number | null;
}

export function ChangedPlanedMealsInWeekplanRequestFromJSON(json: any): ChangedPlanedMealsInWeekplanRequest {
    return ChangedPlanedMealsInWeekplanRequestFromJSONTyped(json, false);
}

export function ChangedPlanedMealsInWeekplanRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangedPlanedMealsInWeekplanRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'planedNumberOfBreakfast': !exists(json, 'planedNumberOfBreakfast') ? undefined : json['planedNumberOfBreakfast'],
        'planedNumberOfLunch': !exists(json, 'planedNumberOfLunch') ? undefined : json['planedNumberOfLunch'],
        'planedNumberOfAfternoonSnack': !exists(json, 'planedNumberOfAfternoonSnack') ? undefined : json['planedNumberOfAfternoonSnack'],
        'planedNumberOfDinner': !exists(json, 'planedNumberOfDinner') ? undefined : json['planedNumberOfDinner'],
    };
}

export function ChangedPlanedMealsInWeekplanRequestToJSON(value?: ChangedPlanedMealsInWeekplanRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'planedNumberOfBreakfast': value.planedNumberOfBreakfast,
        'planedNumberOfLunch': value.planedNumberOfLunch,
        'planedNumberOfAfternoonSnack': value.planedNumberOfAfternoonSnack,
        'planedNumberOfDinner': value.planedNumberOfDinner,
    };
}


