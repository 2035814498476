/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeDetailed,
    RecipeDetailedFromJSON,
    RecipeDetailedFromJSONTyped,
    RecipeDetailedToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeDetailedResponse
 */
export interface RecipeDetailedResponse {
    /**
     * 
     * @type {RecipeDetailed}
     * @memberof RecipeDetailedResponse
     */
    recipe?: RecipeDetailed;
}

export function RecipeDetailedResponseFromJSON(json: any): RecipeDetailedResponse {
    return RecipeDetailedResponseFromJSONTyped(json, false);
}

export function RecipeDetailedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeDetailedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipe': !exists(json, 'recipe') ? undefined : RecipeDetailedFromJSON(json['recipe']),
    };
}

export function RecipeDetailedResponseToJSON(value?: RecipeDetailedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipe': RecipeDetailedToJSON(value.recipe),
    };
}


