/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeMetaWithVariants,
    RecipeMetaWithVariantsFromJSON,
    RecipeMetaWithVariantsFromJSONTyped,
    RecipeMetaWithVariantsToJSON,
} from './';

/**
 * 
 * @export
 * @interface RecipeListResponse
 */
export interface RecipeListResponse {
    /**
     * 
     * @type {Array<RecipeMetaWithVariants>}
     * @memberof RecipeListResponse
     */
    recipes?: Array<RecipeMetaWithVariants> | null;
}

export function RecipeListResponseFromJSON(json: any): RecipeListResponse {
    return RecipeListResponseFromJSONTyped(json, false);
}

export function RecipeListResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RecipeListResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'recipes': !exists(json, 'recipes') ? undefined : (json['recipes'] === null ? null : (json['recipes'] as Array<any>).map(RecipeMetaWithVariantsFromJSON)),
    };
}

export function RecipeListResponseToJSON(value?: RecipeListResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'recipes': value.recipes === undefined ? undefined : (value.recipes === null ? null : (value.recipes as Array<any>).map(RecipeMetaWithVariantsToJSON)),
    };
}


