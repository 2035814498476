import React, { Component } from 'react';
import { EditIngredient } from './EditIngredient';

export class EditIngredientList extends Component {
  constructor(props) {
    super(props);

    if (!!this.props.ingredients && this.props.ingredients.length > 0) {
      this.nextNewId = Math.max(...this.props.ingredients.map(o => (!o.newId) ? 0 : o.newId)) + 1;
    }
  }

  onIngredientsChanged(ingredients) {
    if (!!this.props.onIngredientsChanged) 
      this.props.onIngredientsChanged(ingredients);
  }

  nextNewId = 0;
  getNextNewId() {
    var returnVal = this.nextNewId;
    this.nextNewId = this.nextNewId + 1;
    return returnVal;
  }


  handleIngredientChanged(i, ingredient) {
    var ingredients = this.props.ingredients;
    ingredients[i] = ingredient;
    this.onIngredientsChanged(ingredients);
  }
  handleAddIngredientToTheEnd() {
    var ingredients = this.props.ingredients;
    ingredients.push({ newId: this.getNextNewId()});
    this.onIngredientsChanged(ingredients);
  }
  handleDeleteIngredient(i) {
    var ingredients = this.props.ingredients;
    ingredients.splice(i, 1);
    this.onIngredientsChanged(ingredients);
  }




  render() {
    return (
      <ul className="list-group">
        {this.props.ingredients.map((ingredient, i) => <EditIngredient ingredient={ingredient}
          key={(!!ingredient.id) ? ingredient.id : "new_" + ingredient.newId}
          onDelete={() => this.handleDeleteIngredient(i)}
          onIngredientChanged={(ingredient) => this.handleIngredientChanged(i, ingredient)}
          validResult={(!!this.props.validResult[i]) ? this.props.validResult[i] : {}}
          allIngredients={this.props.allIngredients} />)}

        <li className="list-group-item" style={{ background: "transparent" }}>
          <input type="submit" value="Zutat hinzufügen" className="btn btn-primary" onClick={() => this.handleAddIngredientToTheEnd()} />
        </li>
      </ul>
    );
  }
}
