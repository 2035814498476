import React, { Component } from 'react';

export class EditIngredient extends Component {

  onIngredientChanged(ingredient) {
    if (!!this.props.onIngredientChanged) 
      this.props.onIngredientChanged(ingredient);
  }
  onDelete() {
    if (!!this.props.onDelete)
      this.props.onDelete();
  }


  handleIngredientChange(event) {
    var ingredient = this.props.ingredient;
    ingredient.ingredientId = event.target.value;
    ingredient.ingredientName = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text

    var curIngredient = this.props.allIngredients.filter(x => x.id === Number(event.target.value))[0];

    var defaultPortion = curIngredient.portions.filter(x => x.isDefault)[0]
    ingredient.portionId = defaultPortion.id;
    ingredient.portionName = defaultPortion.name;
    this.onIngredientChanged(ingredient);
  }
  handleAmountChange(event) {
    var ingredient = this.props.ingredient;
    ingredient.amountStr = event.target.value;
    ingredient.amount = this.parseNumber(ingredient.amountStr, "de-DE");
    this.onIngredientChanged(ingredient);
  }
  handlePortionChange(event) {
    var ingredient = this.props.ingredient;
    ingredient.portionId = event.target.value;
    ingredient.portionName = event.nativeEvent.target[event.nativeEvent.target.selectedIndex].text
    this.onIngredientChanged(ingredient);
  }


  parseNumber(value, locales = navigator.languages) {
    const example = Intl.NumberFormat(locales).format('1.1');
    const cleanPattern = new RegExp(`[^-+0-9${ example.charAt( 1 ) }]`, 'g');
    const cleaned = value.replace(cleanPattern, '');
    const normalized = cleaned.replace(example.charAt(1), '.');

    return parseFloat(normalized);
  }

  render() {
    const ingredientId = this.props.ingredient.ingredientId || "DEFAULT";
    const isIngredientSelected = ingredientId !== "DEFAULT";
    const areIngredientsLoaded = this.props.allIngredients !== "loading";
    
    const allIngredients = areIngredientsLoaded ? this.props.allIngredients.map(x => {return <option key={x.id} value={x.id}>{x.name}</option>;}) : (
      isIngredientSelected ? <><option key={ingredientId} value={ingredientId}>{this.props.ingredient.ingredientName}</option><option value="DEFAULT" disabled>Lade weitere Zutaten...</option></> :
      <option value="DEFAULT" disabled>Lade Zutaten...</option>
    )

    const amount = this.props.ingredient.amountStr || (this.props.ingredient.amountStr === "" ? "" :
      new Intl.NumberFormat('de-DE').format(this.props.ingredient.amount || 0))
    var portions = <></>;
    var portionId = this.props.ingredient.portionId || "DEFAULT";
    if(areIngredientsLoaded) {
      var curIngredient = this.props.allIngredients.filter(x => x.id === Number(ingredientId));
      curIngredient = curIngredient[0] || {portions: []};
      portions = curIngredient.portions.map(x => {return <option key={x.id} value={x.id}>{x.name}</option>;})
    } else {
      portions = <><option key={portionId} value={portionId}>{this.props.ingredient.portionName}</option><option key="Default" value="DEFAULT" disabled>Lade weitere Portionen...</option></>
    }

    return (
      <li className="list-group-item" style={{background: 'rgba(0, 0, 0, 0)'}} >
        <div className="row">
          <div className="col-md-11">
            <div className="row">
              <div className="col-md-6">
                <select className="custom-select" value={ingredientId} onChange={(event) => this.handleIngredientChange(event)}>
                  {(!isIngredientSelected && areIngredientsLoaded) ? <option value="DEFAULT" disabled>Zutat auswählen...</option> : <></>}
                  {allIngredients}
                </select>
              </div>
              <div className="col-md-6">
                <div className="input-group">
                  <input value={amount} onChange={(event) => this.handleAmountChange(event)} disabled={!isIngredientSelected} />
                  <select className="custom-select" value={portionId} onChange={(event) => this.handlePortionChange(event)} disabled={!isIngredientSelected}>
                    {portions || <></>}
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-1">
            <input type="submit" value="Del" className="btn btn-danger" onClick={() => this.onDelete()} />
          </div>
        </div>
      </li>
    );
  }
}
