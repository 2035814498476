import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { generatePath } from "react-router";

import ApiBackend from '../api-backend/ApiBackend'
import DeleteModal from './DeleteModal';
import { ModalManager } from 'react-dynamic-modal';
import { withRouter } from '../../routing/withRouter';
import AppRoutes, { createFullPath } from '../../routing/AppRoutes';


class Detail extends Component {
  constructor(props) {
    super(props);

    // take the state either from the location state or set it to not loaded
    this.state = {
      loaded : !!props.location.state?.recipe,
      recipe : props.location.state?.recipe || {},
    };
  }
    
    
  componentDidMount() {
    if(!this.state.loaded) this.update(); // initial update
  }


  async update() {
    const recipe = (await ApiBackend.Recipe.id({id: this.props.params.id})).recipe;
  
    this.setState(state => ({
      loaded : true,
      recipe : recipe,
    }));
  }
  
  openDeleteModal(recipe) {
    ModalManager.open(<DeleteModal recipe={recipe} onStateUpdate={state => this.OnDeleteFinished(state)} onRequestClose={() => true}/>);
  }
  OnDeleteFinished(state) {
    this.props.navigate("/" + AppRoutes.Recipes.routeProps.path, {state: { recipes: state }})
  }


  componentDidUpdate () {
    if(this.state.loaded) {
      const paramId = this.props.params.id;
      const stateId = this.state.recipe.id;

      /*eslint eqeqeq: "off"*/  
      if(paramId != stateId) {
        this.setState({loaded: false, recipe: {}})
        this.update();
      }
    }

  }

  render() {
    const { recipe, loaded } = this.state;

    const variants = (!loaded || !recipe.hasVariants) ? <></>: 
      <dl className="row">
        <dt className="col-sm-2">Varianten</dt>
        <dd className="col-sm-10">
          {recipe.variants.map((variant, i) => 
            <div key={i + "_" + variant.id}>
              <Link to={generatePath(createFullPath("Recipe","Detail"), {id: variant.id})} >{variant.name}</Link>
            </div>
          )}
        </dd>
      </dl>
            
    const baseRecipe = (!loaded || !recipe.isVariant) ? <></> :
      <dl className="row">
        <dt className="col-sm-2">Basisrezept</dt>
        <dd className="col-sm-10"><Link to={generatePath(createFullPath("Recipe","Detail"), {id: recipe.baseRecipeId})}>{recipe.baseRecipeName}</Link></dd>
      </dl>


    return (
      <>{ !this.state.loaded ? (<p><em>Lade Rezept...</em></p>) : (
        <>
          <h1>{recipe.baseRecipeName}</h1>
          {recipe.isvariant ? <></> : <h3>{recipe.name}</h3>}

          <div>
            <hr />
            <dl className="row">
              <dt className="col-sm-2">Portionen</dt>
              <dd className="col-sm-10">{recipe.servings}</dd>
            </dl>
            {variants}
            {baseRecipe}
          </div>
  
          <StepList steps={recipe.steps} />
          {recipe.subchapters.map((subchapter) => <Subchapter subchapter={subchapter} />)}
          
          <div>
            <Link to={{
              pathname: generatePath(createFullPath("Recipe","Edit"), {id: recipe.id}),
              state: { recipe: recipe }
            }}>Bearbeiten</Link> |&nbsp;
            <Link to="#" onClick={() => this.openDeleteModal(recipe)}>Löschen</Link> |&nbsp;
            {recipe.isVariant ? <></> : <><Link to={generatePath(createFullPath("Recipe","CreateVariant"), {id: recipe.id})}>Variante erstellen</Link> |&nbsp;</>}
            <Link to={"/" + AppRoutes.Recipes.routeProps.path}>Zurück zur Liste</Link>
          </div>

        </>)}
      </>
    )
  }
}

export default withRouter(Detail)

class Subchapter extends Component {
  render() {
    return (
      <>
        <h3>{this.props.subchapter.headline}</h3>
        <StepList steps={this.props.subchapter.steps} />
      </>
    )
  }
}

class StepList extends Component {
  render() {


    return (
      <>
        {this.props.steps.length === 0  ? <></> :
          <div className="jumbotron" style={{padding: "1rem"}}>
            <table className="table table-striped table-striped-white" style={{margin: "0pt"}}>
              <tbody>{this.props.steps.map((step) => <Step step={step} key={step.id} />)}</tbody>
            </table>
          </div>
        }
      </>
    )
  }
}


class Step extends Component {

  render() {
    return (
      <tr>
        <td>
          <div className="row">
            <div className="col-md-3"><IngredientList ingredients={this.props.step.ingredients} /></div>
            <div className="col-md-9">
              {this.props.step.text.split('\n').map(function(item, key) {
                return (<span key={key}>{item}<br/></span>)
              })}
            </div>
          </div>
        </td>
      </tr>
    )}
}

class IngredientList extends Component {

  render() {
    return (
      <table className="table table-nostriped" style={{margin: "0pt"}}>
        <tbody>
          {this.props.ingredients.map((ingredient) => 
              <Ingredient key={ingredient.id} ingredient={ingredient} />)}
        </tbody>
      </table>

    )
  }
}


class Ingredient extends Component {

  render() {
    return (
      <tr>
        <td style={{textAlign: "right"}}>{this.props.ingredient.amount} {this.props.ingredient.portionName}</td>
        <td>{this.props.ingredient.ingredientName}</td>
      </tr>
    )
  }
}