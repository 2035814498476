/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CreateRecipeRequest,
    CreateRecipeRequestFromJSON,
    CreateRecipeRequestToJSON,
    CreateVariantRecipeRequest,
    CreateVariantRecipeRequestFromJSON,
    CreateVariantRecipeRequestToJSON,
    RecipeDetailedResponse,
    RecipeDetailedResponseFromJSON,
    RecipeDetailedResponseToJSON,
    RecipeMetaWithVariants,
    RecipeMetaWithVariantsFromJSON,
    RecipeMetaWithVariantsToJSON,
    UpdateRecipeRequest,
    UpdateRecipeRequestFromJSON,
    UpdateRecipeRequestToJSON,
} from '../models';

export interface RecipeApiCreateRequest {
    createRecipeRequest?: CreateRecipeRequest;
}

export interface RecipeApiIdRequest {
    id: number;
}

export interface RecipeApiIdCreateVariantRequest {
    id: number;
    createVariantRecipeRequest?: CreateVariantRecipeRequest;
}

export interface RecipeApiIdDeleteRequest {
    id: number;
}

export interface RecipeApiIdUpdateRequest {
    id: number;
    updateRecipeRequest?: UpdateRecipeRequest;
}

/**
 * 
 */
export class RecipeApi extends runtime.BaseAPI {

    /**
     */
    async createRaw(requestParameters: RecipeApiCreateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecipeDetailedResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Recipe/create`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateRecipeRequestToJSON(requestParameters.createRecipeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async create(requestParameters: RecipeApiCreateRequest, initOverrides?: RequestInit): Promise<RecipeDetailedResponse> {
        const response = await this.createRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idRaw(requestParameters: RecipeApiIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecipeDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling id.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Recipe/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async id(requestParameters: RecipeApiIdRequest, initOverrides?: RequestInit): Promise<RecipeDetailedResponse> {
        const response = await this.idRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idCreateVariantRaw(requestParameters: RecipeApiIdCreateVariantRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecipeDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idCreateVariant.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Recipe/{id}/createVariant`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateVariantRecipeRequestToJSON(requestParameters.createVariantRecipeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idCreateVariant(requestParameters: RecipeApiIdCreateVariantRequest, initOverrides?: RequestInit): Promise<RecipeDetailedResponse> {
        const response = await this.idCreateVariantRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idDeleteRaw(requestParameters: RecipeApiIdDeleteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<RecipeMetaWithVariants>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idDelete.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Recipe/{id}/delete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RecipeMetaWithVariantsFromJSON));
    }

    /**
     */
    async idDelete(requestParameters: RecipeApiIdDeleteRequest, initOverrides?: RequestInit): Promise<Array<RecipeMetaWithVariants>> {
        const response = await this.idDeleteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async idUpdateRaw(requestParameters: RecipeApiIdUpdateRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<RecipeDetailedResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling idUpdate.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Bearer", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/api/Recipe/{id}/update`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRecipeRequestToJSON(requestParameters.updateRecipeRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RecipeDetailedResponseFromJSON(jsonValue));
    }

    /**
     */
    async idUpdate(requestParameters: RecipeApiIdUpdateRequest, initOverrides?: RequestInit): Promise<RecipeDetailedResponse> {
        const response = await this.idUpdateRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
