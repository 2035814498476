/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    BringListSummary,
    BringListSummaryFromJSON,
    BringListSummaryFromJSONTyped,
    BringListSummaryToJSON,
} from './';

/**
 * 
 * @export
 * @interface BringListsResponse
 */
export interface BringListsResponse {
    /**
     * 
     * @type {Array<BringListSummary>}
     * @memberof BringListsResponse
     */
    lists?: Array<BringListSummary> | null;
}

export function BringListsResponseFromJSON(json: any): BringListsResponse {
    return BringListsResponseFromJSONTyped(json, false);
}

export function BringListsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BringListsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'lists': !exists(json, 'lists') ? undefined : (json['lists'] === null ? null : (json['lists'] as Array<any>).map(BringListSummaryFromJSON)),
    };
}

export function BringListsResponseToJSON(value?: BringListsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'lists': value.lists === undefined ? undefined : (value.lists === null ? null : (value.lists as Array<any>).map(BringListSummaryToJSON)),
    };
}


