import React, { Component } from 'react';
import { EditIngredientList } from '../Ingredient/EditIngredientList';

/**
 * TODO: Add Ingredients button
 */

export class EditStep extends Component {

  onStepChanged(step) {
    if (!!this.props.onStepChanged) 
      this.props.onStepChanged(step);
  }
  onDelete() {
    if (!!this.props.onDelete)
      this.props.onDelete();
  }


  handleTextChange(event) {
    var step = this.props.step;
    step.text = event.target.value;
    this.onStepChanged(step);
  }
  handleIngredientsChanged(ingredients) {
    var step = this.props.step;
    step.ingredients = ingredients;
    this.onStepChanged(step);
  }


  render() {
    return (
      <tr>
        <td>
          <div className="row">
            <div className="col-md-11">
              <div className="row">
                <div className="col-md-12">
                  <EditIngredientList           
                    ingredients={(!this.props.step.ingredients) ? [] : this.props.step.ingredients}
                    onIngredientsChanged={(ingredients) => this.handleIngredientsChanged(ingredients)}
                    validResult={(!!this.props.validResult.ingredients) ? this.props.validResult.ingredients : {}}
                    allIngredients={this.props.ingredients} />
                  <br />

                  <textarea rows="10" className="form-control" value={this.props.step.text} style={{ backgroundColor: "rgba(255,255,255,0.5)" }} onChange={(event) => this.handleTextChange(event)}></textarea>

                </div>
              </div>

            </div>
            <div className="col-md-1">
              <input type="submit" value="Del" className="btn btn-danger" onClick={() => this.onDelete()} />
            </div>
          </div>
        </td>
      </tr>
    );
  }
}
