import React, { Component } from 'react';
import { EditPortion } from './EditPortion';

export class EditPortions extends Component {
  onPortionsChanged(portions) {
    if (!!this.props.onPortionsChanged)
      this.props.onPortionsChanged(portions);
  }

  handleDefaultPortionChanged(i) {
    var portions = this.props.portions;
    portions.forEach((el)=>{el.isDefault = false;}) 
    portions[i].isDefault = true;
    this.onPortionsChanged(portions)
  }

  handlePortionChanged(portion, i) {
    var portions = this.props.portions;
    portions[i] = portion;
    this.onPortionsChanged(portions)
  }


  nextNewId = 1;
  getNextNewId() {
    var returnVal = this.nextNewId;
    this.nextNewId = this.nextNewId + 1;
    return returnVal;
  }
  handleAddPortionToTheEnd() {
    var portions = this.props.portions;
    portions.push({ newId: this.getNextNewId(), name: "", weight: 1, isApproximal: false });
    this.onPortionsChanged(portions);
  }
  handleDeletePortion(i) {
    var portions = this.props.portions;
    portions.splice(i, 1);
    this.onPortionsChanged(portions);
  }

  render() {
    const {validResult} = this.props;

    return (
      <table className="table table-striped">
        <tbody>
          <tr>
            <th>Verfügbare Portionen</th>
            <th>Portionsgewicht</th>
            <th>Standart</th>
            <th>Ungefähr</th>
            <th></th>
          </tr>
          {this.props.portions.map((portion, i) => {
            return <EditPortion key={portion.id ?? ("new:" + portion.newId)} portion={portion}
              onDefaultPortionChanged={(portion) => this.handleDefaultPortionChanged(i)}
              onDelete={() => this.handleDeletePortion(i)}
              onPortionChanged={(portion) => this.handlePortionChanged(portion, i)} 
              validResult={validResult[i] ?? {} }
              />;
          }
          )}
          <tr>
            <td colSpan="5">
              <input type="submit" value="Neue hinzufügen" className="btn btn-success" 
                onClick={() => this.handleAddPortionToTheEnd()} />
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
}
