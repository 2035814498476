import React, { Component } from 'react';
import ApiBackend from '../api-backend/ApiBackend';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Collapse } from 'reactstrap';

export class PlanedMeals extends Component {

  goToEditMode(elm) { 
    this.setState({ 
      editMode: elm, 
      editValue: this.props.weekplan.mealTypes[elm].planedNumberOfMeals 
    }); 
  }
  cancelEditMode(elm) {
    if (this.state.editMode === elm)
      this.setState({ editMode: "" });
  }

  state = { editMode: "", editValue: 0, planedMealsIsOpen: false };

  togglePlanedMealsOpen() {
    this.setState({planedMealsIsOpen: !this.state.planedMealsIsOpen})
  }

  async handleSafeEvent() {
    var data = {};
    const editMode = this.state.editMode.charAt(0).toUpperCase() + this.state.editMode.slice(1);
    data["planedNumberOf" + editMode] = this.state.editValue;

    const value = { id: this.props.weekplan.id, changedPlanedMealsInWeekplanRequest: data };
    var response = await ApiBackend.Weekplan.idChangedPlanedMeals(value);

    this.setState({ editMode: "" });

    if (!!this.props.onWeekplanChanged)
      this.props.onWeekplanChanged(response.weekplan);
  }

  handleValueChange(event) { this.setState({ editValue: event.target.value }); }

  renderPlanedNumber(elm, data) {
    const { weekplan } = this.props;
    const isEditable = !!this.props.onWeekplanChanged;
    const planedNumber = weekplan.mealTypes[elm].planedNumberOfMeals;

    const noEditCell = <td style={{ "padding": "1.18rem 0.75rem" }}>{planedNumber} {isEditable ? <> <FontAwesomeIcon style={{ "marginLeft": "1em" }} icon={faEdit} onClick={() => this.goToEditMode(elm)} /></> : <></>}</td>;
    const editCell = <td>
      <div className="row">
        <div className="col-md-4">
          <input className="form-control" type="number" min="0" step="1" value={this.state.editValue} onChange={event => this.handleValueChange(event)} />
        </div>
        <div className="col-md-8">
          <input type="submit" value={this.props.saveButton} className="btn btn-primary" onClick={() => this.handleSafeEvent()} />
          <button type="button" className="btn btn-danger" onClick={() => this.cancelEditMode(elm)} style={{ "marginLeft": "1em" }}><FontAwesomeIcon icon={faTimes} /></button>
        </div>
      </div>
    </td>;


    return (<tr key={elm}>
      <td style={{ "width": "1em", "padding": "1.175rem 0.75rem" }}>{data.name}:</td>
      {this.state.editMode === elm ? editCell : noEditCell}
    </tr>);
  }


  render() {
    const types = this.props.weekplan.mealTypes;

    return (
      <>
        <dt className="col-sm-2" style={{"marginLeft": "-18px"}} onClick={() => this.togglePlanedMealsOpen([])}>
          <div className={`${!this.state.planedMealsIsOpen ? "isRotated" : "isNotRotated"}`} style={{"display":"inline"}}>
            <FontAwesomeIcon style={{"display":"inline-block"}} icon={faChevronDown}/>
          </div> Geplante Anzahl
        </dt>

        <dd className="col-sm-10" style={{"paddingLeft": "33px"}}>
          <Collapse isOpen={this.state.planedMealsIsOpen}>
          <table className="table table-hover">
            <tbody>
              {Object.keys(types).map(elm => this.renderPlanedNumber(elm, types[elm]))}
            </tbody>
          </table>
          </Collapse>
          <Collapse isOpen={!this.state.planedMealsIsOpen}>
            {Object.keys(types).map(elm => "" + types[elm].planedNumberOfMeals + " " + types[elm].name).join(", ")}
          </Collapse>
        </dd>
      </>
    );

  }
}
