import React,{Component} from 'react';
import { Modal, ModalManager, Effect} from 'react-dynamic-modal';
import { generatePath } from 'react-router-dom';
import SelectSearch from 'react-select-search';
import { createFullPath } from '../../routing/AppRoutes';
import ApiBackend from '../api-backend/ApiBackend';
import './SelectSearch.css'

class AddMealModal extends Component{

  state = {
    data : {}, 
    loaded: false, 
    basisId: undefined, 
    basisServings: 0, 
    variants: {}
  }
  
  componentDidMount() {
    if(!this.state.loaded) { this.update(); } // initial update
    this.interval = setInterval(() => this.update(), 100000);
  }
  componentWillUnmount() { clearInterval(this.interval); }
  async update() { this.setState({loaded : true, data : (await ApiBackend.Recipes.listAll())});}
  handleRecipeChange(id) { this.setState({basisId: id, basisServings: 0, variants: {}}) }
  handleBasisServingsChange(event){ this.setState({basisServings: event.target.value}); }
  handleVariantServingsChange(event, variantId) { 
    var variants = this.state.variants;
    variants[variantId] = event.target.value;
    this.setState({variants: variants}); 
  }

  
  async onAdd() {
    const variants = Object.keys(this.state.variants).map(x => {
      return {variantId: x, servings: this.state.variants[x]}
    })

    var response = await ApiBackend.Weekplan.idmealTypeAddMeal({
      id: this.props.weekplan.id,
      mealType: this.props.mealType,
      addMealInWeekplanRequest: {
        recipeId: this.state.basisId, 
        servings: this.state.basisServings,
        variants: variants,
      }})

    this.props.onMealAdded(response.weekplan)
    ModalManager.close();
  }



  renderBody() {
    if(!this.state.loaded) return "Rezepte werden geladen..."

    /** TODO: Only show the recipes that fit the mealType => mealType(s) need to be stored in the database next to the recipe*/

    const options = this.state.data.recipes.map(function(r){ return { name: r.name, value: r.id}; });
    return(<>
      <SelectSearch options={options} onChange={(id) => this.handleRecipeChange(id)} value={this.state.basisId}  placeholder="Rezept auswählen" search/>
      <br/>
      {this.renderBodyRecipe()}
    </>)
  }
  renderBodyRecipe() {
    if(!this.state.basisId) return <></> // in case nothing selected yet

    const recipe = this.state.data.recipes.filter(x => x.id === this.state.basisId)[0]
    return(<>
      <h3>{recipe.name}</h3>

      <div className="col-md-10">
        <div className="input-group">
          <div className="input-group-prepend" style={{"minWidth":"50%"}}>
            <span className="input-group-text" style={{"minWidth":"100%"}}>
              <a href={generatePath(createFullPath("Recipe", "Detail"), {id: recipe.id})} target="_blank" rel="noreferrer" tabIndex="-1">Basisvariante</a>
            </span>
          </div>
          <input className="form-control" value={this.state.basisServings} style={{"maxWidth":"50%"}} onChange={(event) => this.handleBasisServingsChange(event)} />
        </div>

        {recipe.hasVariants && recipe.variants.map(variant => {
          const value = this.state.variants[variant.id] === undefined ? 0 : this.state.variants[variant.id]

          return(<div className="input-group">
            <div className="input-group-prepend" style={{"minWidth":"50%"}}>
              <span className="input-group-text" style={{"minWidth":"100%"}}>
                <a href={generatePath(createFullPath("Recipe", "Detail"), {id: variant.id})} target="_blank" rel="noreferrer"  tabIndex="-1">{variant.name}</a>
              </span>
            </div>
            <input className="form-control" value={value} style={{"maxWidth":"50%"}} onChange={(event) => this.handleVariantServingsChange(event, variant.id)} />
        </div>)})}

        
      </div>
      
    </>)
  }

  render(){

    return (
      <Modal
        onRequestClose={this.props.onRequestClose} effect={Effect.ScaleUp}>

        <div className="modal-header"><h4 className="modal-title">Essen hinzufügen</h4></div>
        <div className="modal-body" style={{"height": "30em"}}>{this.renderBody()}</div>
        <div className="modal-footer">
          <button type="button" className="btn btn-success" onClick={() => this.onAdd(1)}>Hinzufügen</button>
          <button type="button" className="btn btn-default" onClick={ModalManager.close}>Schließen</button>
        </div>
        

      </Modal>
    );
  }
}

export default AddMealModal
