import React, { Component } from 'react';
import apiBackend from '../api-backend/ApiBackend';
import EditOrCreate from './EditOrCreate';
import { generatePath } from "react-router";
import { withRouter } from '../../routing/withRouter';
import { createFullPath } from '../../routing/AppRoutes';

class Create extends Component {
  async handleSafeEvent(ingredient) {
    var response = await apiBackend.Ingredient.create({createIngredientRequest: {ingredient : ingredient}});
    var forewardPath = generatePath(createFullPath("Ingredient","Detail"), {id: response.ingredient.id})

    this.props.navigate(forewardPath, {state: { ingredient: response.ingredient }})
  }
  
  render() { return (<EditOrCreate title="Zutat erstellen" onSaveEvent={(ingredient) => this.handleSafeEvent(ingredient)} saveButton="Erstellen" />); }
}

export default withRouter(Create)
