/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    PortionDetailedNoId,
    PortionDetailedNoIdFromJSON,
    PortionDetailedNoIdFromJSONTyped,
    PortionDetailedNoIdToJSON,
} from './';

/**
 * 
 * @export
 * @interface IngredientDetailedNoIds
 */
export interface IngredientDetailedNoIds {
    /**
     * 
     * @type {string}
     * @memberof IngredientDetailedNoIds
     */
    name?: string | null;
    /**
     * 
     * @type {Array<PortionDetailedNoId>}
     * @memberof IngredientDetailedNoIds
     */
    portions?: Array<PortionDetailedNoId> | null;
}

export function IngredientDetailedNoIdsFromJSON(json: any): IngredientDetailedNoIds {
    return IngredientDetailedNoIdsFromJSONTyped(json, false);
}

export function IngredientDetailedNoIdsFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngredientDetailedNoIds {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'portions': !exists(json, 'portions') ? undefined : (json['portions'] === null ? null : (json['portions'] as Array<any>).map(PortionDetailedNoIdFromJSON)),
    };
}

export function IngredientDetailedNoIdsToJSON(value?: IngredientDetailedNoIds | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'portions': value.portions === undefined ? undefined : (value.portions === null ? null : (value.portions as Array<any>).map(PortionDetailedNoIdToJSON)),
    };
}


