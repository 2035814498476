/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PortionSummary
 */
export interface PortionSummary {
    /**
     * 
     * @type {number}
     * @memberof PortionSummary
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof PortionSummary
     */
    name?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PortionSummary
     */
    isDefault?: boolean;
}

export function PortionSummaryFromJSON(json: any): PortionSummary {
    return PortionSummaryFromJSONTyped(json, false);
}

export function PortionSummaryFromJSONTyped(json: any, ignoreDiscriminator: boolean): PortionSummary {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'isDefault': !exists(json, 'isDefault') ? undefined : json['isDefault'],
    };
}

export function PortionSummaryToJSON(value?: PortionSummary | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'isDefault': value.isDefault,
    };
}


