/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    IngredientMeta,
    IngredientMetaFromJSON,
    IngredientMetaFromJSONTyped,
    IngredientMetaToJSON,
    TypeMeta,
    TypeMetaFromJSON,
    TypeMetaFromJSONTyped,
    TypeMetaToJSON,
} from './';

/**
 * 
 * @export
 * @interface IngredientDeletedResponse
 */
export interface IngredientDeletedResponse {
    /**
     * 
     * @type {boolean}
     * @memberof IngredientDeletedResponse
     */
    success?: boolean;
    /**
     * 
     * @type {Array<IngredientMeta>}
     * @memberof IngredientDeletedResponse
     */
    ingredients?: Array<IngredientMeta> | null;
    /**
     * 
     * @type {Array<TypeMeta>}
     * @memberof IngredientDeletedResponse
     */
    usedIn?: Array<TypeMeta> | null;
}

export function IngredientDeletedResponseFromJSON(json: any): IngredientDeletedResponse {
    return IngredientDeletedResponseFromJSONTyped(json, false);
}

export function IngredientDeletedResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): IngredientDeletedResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'success': !exists(json, 'success') ? undefined : json['success'],
        'ingredients': !exists(json, 'ingredients') ? undefined : (json['ingredients'] === null ? null : (json['ingredients'] as Array<any>).map(IngredientMetaFromJSON)),
        'usedIn': !exists(json, 'usedIn') ? undefined : (json['usedIn'] === null ? null : (json['usedIn'] as Array<any>).map(TypeMetaFromJSON)),
    };
}

export function IngredientDeletedResponseToJSON(value?: IngredientDeletedResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'success': value.success,
        'ingredients': value.ingredients === undefined ? undefined : (value.ingredients === null ? null : (value.ingredients as Array<any>).map(IngredientMetaToJSON)),
        'usedIn': value.usedIn === undefined ? undefined : (value.usedIn === null ? null : (value.usedIn as Array<any>).map(TypeMetaToJSON)),
    };
}


