import React,{Component} from 'react';
import { Modal,ModalManager,Effect} from 'react-dynamic-modal';
import apiBackend from '../api-backend/ApiBackend';
 
class DeleteModal extends Component{

   async onDelete(id) {
      var recipes = await apiBackend.Recipe.idDelete({id: id})

      this.props.onStateUpdate(recipes)
      ModalManager.close();
   }


   render(){
      const { recipe, onRequestClose } = this.props;

      const headline = (recipe.isVariant ? "Soll die Rezeptvariante" : (!recipe.hasVariants ? "Soll das Rezept" : "Soll das Rezept inkl. seiner Varianten")) + " gelöscht werden?";
      const variants = (!recipe.hasVariants) ? <></> : 
         <ul>
            {recipe.variants.map((variant) => <li key={variant.id}>{variant.name}</li>)}
         </ul>
      const baseRecipeName = recipe.isVariant ? recipe.baseRecipeName + " - " : "";

      return (
         <Modal
            onRequestClose={onRequestClose} effect={Effect.ScaleUp}>

            <div className="modal-header">
               <h4 className="modal-title">{headline}</h4>
            </div>
            <div className="modal-body">
               {baseRecipeName}{recipe.name}
               {variants}
            </div>
            <div className="modal-footer">
               <button type="button" className="btn btn-danger" onClick={() => this.onDelete(recipe.id)}>Löschen</button>
               <button type="button" className="btn btn-default" onClick={ModalManager.close}>Schließen</button>
            </div>
            

         </Modal>
      );
   }
}

export default DeleteModal