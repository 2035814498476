import React, { Component } from 'react';

export class EditPortion extends Component {

  onPortionChanged(portion) {
    if (!!this.props.onPortionChanged) this.props.onPortionChanged(portion);
  }
  onDelete() {
    if (!!this.props.onDelete) this.props.onDelete();
  }


  handleNameChange(event) {
    var portion = this.props.portion;
    portion.name = event.target.value;
    this.onPortionChanged(portion);
  }
  handleWeightChange(event) {
    var portion = this.props.portion;
    portion.weight = event.target.value;

    var numberOfComma = portion.weight.replace(/[^,]/g, "").length;
    var hasPoint = portion.weight.indexOf(".") > 0;
    if(!hasPoint && numberOfComma === 1) { // only contains one , but no . => replace , with .
      portion.weight = portion.weight.replace(",", ".")
    }

    this.onPortionChanged(portion);
  }
  handleApproxChanged(event) {
    var portion = this.props.portion;
    portion.isApproximal = event.target.checked;
    this.onPortionChanged(portion);
  }

    handleDefaultChange() {
    if (!!this.props.onDefaultPortionChanged)
      this.props.onDefaultPortionChanged(this.props.portion);
  }

  render() {
    const {portion} = this.props;

    return (
      <tr>
        <td>
          <input value={portion.name} onChange={(event) => this.handleNameChange(event)} className="form-control" />
          <span className="text-danger field-validation-valid">{(!!this.props.validResult.name) ? this.props.validResult.name : ""}</span>
        </td>
        <td>
          <div className="input-group">
            <input  value={portion.weight} onChange={(event) => this.handleWeightChange(event)} className="form-control" />
            <div className="input-group-append"><span className="input-group-text">g</span></div>
          </div>
          <span className="text-danger field-validation-valid">{(!!this.props.validResult.weight) ? this.props.validResult.weight : ""}</span>
        </td>
        <td style={{verticalAlign : "middle", textAlign: "center"}}>
          <input type="radio" name="radio-group-default" 
            value={portion.id} onChange={() => this.handleDefaultChange()} checked={portion.isDefault} />
        </td>
        <td style={{verticalAlign : "middle", textAlign: "center"}}>
          <input type="checkbox" checked={portion.isApproximal} onChange={(event) => this.handleApproxChanged(event)}/>
        </td>
        <td>
          <input type="submit" value="Speichern" className="btn btn-primary" style={{display:"none"}}  />
          <input type="submit" value="Löschen" className="btn btn-danger" onClick={() => this.onDelete()} />
        </td>
      </tr>
    );
  }
}
