/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    ShoppingListAmountSummary,
    ShoppingListAmountSummaryFromJSON,
    ShoppingListAmountSummaryFromJSONTyped,
    ShoppingListAmountSummaryToJSON,
    ShoppingListTargetDetail,
    ShoppingListTargetDetailFromJSON,
    ShoppingListTargetDetailFromJSONTyped,
    ShoppingListTargetDetailToJSON,
} from './';

/**
 * 
 * @export
 * @interface ShoppingListDetailed
 */
export interface ShoppingListDetailed {
    /**
     * 
     * @type {number}
     * @memberof ShoppingListDetailed
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof ShoppingListDetailed
     */
    name?: string | null;
    /**
     * 
     * @type {ShoppingListTargetDetail}
     * @memberof ShoppingListDetailed
     */
    target?: ShoppingListTargetDetail;
    /**
     * 
     * @type {Array<ShoppingListAmountSummary>}
     * @memberof ShoppingListDetailed
     */
    entries?: Array<ShoppingListAmountSummary> | null;
}

export function ShoppingListDetailedFromJSON(json: any): ShoppingListDetailed {
    return ShoppingListDetailedFromJSONTyped(json, false);
}

export function ShoppingListDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): ShoppingListDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'target': !exists(json, 'target') ? undefined : ShoppingListTargetDetailFromJSON(json['target']),
        'entries': !exists(json, 'entries') ? undefined : (json['entries'] === null ? null : (json['entries'] as Array<any>).map(ShoppingListAmountSummaryFromJSON)),
    };
}

export function ShoppingListDetailedToJSON(value?: ShoppingListDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'target': ShoppingListTargetDetailToJSON(value.target),
        'entries': value.entries === undefined ? undefined : (value.entries === null ? null : (value.entries as Array<any>).map(ShoppingListAmountSummaryToJSON)),
    };
}


