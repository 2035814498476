/* tslint:disable */
/* eslint-disable */
/**
 * WeekPurchaseManager
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    RecipeMeta,
    RecipeMetaFromJSON,
    RecipeMetaFromJSONTyped,
    RecipeMetaToJSON,
    WeekplanMealVariantDetailed,
    WeekplanMealVariantDetailedFromJSON,
    WeekplanMealVariantDetailedFromJSONTyped,
    WeekplanMealVariantDetailedToJSON,
} from './';

/**
 * 
 * @export
 * @interface WeekplanMealDetailed
 */
export interface WeekplanMealDetailed {
    /**
     * 
     * @type {number}
     * @memberof WeekplanMealDetailed
     */
    id?: number;
    /**
     * 
     * @type {RecipeMeta}
     * @memberof WeekplanMealDetailed
     */
    recipe?: RecipeMeta;
    /**
     * 
     * @type {number}
     * @memberof WeekplanMealDetailed
     */
    servings?: number;
    /**
     * 
     * @type {Array<WeekplanMealVariantDetailed>}
     * @memberof WeekplanMealDetailed
     */
    variants?: Array<WeekplanMealVariantDetailed> | null;
}

export function WeekplanMealDetailedFromJSON(json: any): WeekplanMealDetailed {
    return WeekplanMealDetailedFromJSONTyped(json, false);
}

export function WeekplanMealDetailedFromJSONTyped(json: any, ignoreDiscriminator: boolean): WeekplanMealDetailed {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'recipe': !exists(json, 'recipe') ? undefined : RecipeMetaFromJSON(json['recipe']),
        'servings': !exists(json, 'servings') ? undefined : json['servings'],
        'variants': !exists(json, 'variants') ? undefined : (json['variants'] === null ? null : (json['variants'] as Array<any>).map(WeekplanMealVariantDetailedFromJSON)),
    };
}

export function WeekplanMealDetailedToJSON(value?: WeekplanMealDetailed | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'recipe': RecipeMetaToJSON(value.recipe),
        'servings': value.servings,
        'variants': value.variants === undefined ? undefined : (value.variants === null ? null : (value.variants as Array<any>).map(WeekplanMealVariantDetailedToJSON)),
    };
}


