import React, { Component } from 'react';
import { EditSubchapter } from './EditSubchapter';

export class EditSubchapterList extends Component {
  constructor(props) {
    super(props);

    if (!!this.props.subchapters && this.props.subchapters.length > 0) {
      this.nextOrder = Math.max(...this.props.subchapters.map(o => (!o.order) ? 0 : o.order)) + 1;
      this.nextNewId = Math.max(...this.props.subchapters.map(o => (!o.newId) ? 0 : o.newId)) + 1;
    }
  }

  onSubchaptersChanged(subchapters) {
    if (!!this.props.onSubchaptersChanged) 
      this.props.onSubchaptersChanged(subchapters);
  }

  nextOrder = 0;
  nextNewId = 0;
  getNextOrder() {
    var returnVal = this.nextOrder;
    this.nextOrder = this.nextOrder + 1;
    return returnVal;
  }
  getNextNewId() {
    var returnVal = this.nextNewId;
    this.nextNewId = this.nextNewId + 1;
    return returnVal;
  }


  handleSubchapterChanged(i, subchapter) {
    var subchapters = this.props.subchapters;
    subchapters[i] = subchapter;
    this.onSubchaptersChanged(subchapters);
  }
  handleAddSubchapterToTheEnd() {
    var subchapters = this.props.subchapters;
    subchapters.push({ order: this.getNextOrder(), newId: this.getNextNewId() });
    this.onSubchaptersChanged(subchapters);
  }
  handleDeleteSubchapter(i) {
    var subchapters = this.props.subchapters;
    subchapters.splice(i, 1);
    this.onSubchaptersChanged(subchapters);
  }




  render() {
    return (
      <>
        {this.props.subchapters.map((subchapter, i) => <EditSubchapter subchapter={subchapter}
          key={(!!subchapter.id) ? subchapter.id : "new_" + subchapter.newId}
          onDelete={() => this.handleDeleteSubchapter(i)}
          onSubchapterChanged={(subchapter) => this.handleSubchapterChanged(i, subchapter)}
          validResult={(!!this.props.validResult[i]) ? this.props.validResult[i] : {}}
          ingredients={this.props.ingredients}  />)}

        <input type="submit" value="Unterkapitel hinzufügen" className="btn btn-primary" onClick={() => this.handleAddSubchapterToTheEnd()} />
      </>
    );
  }
}
